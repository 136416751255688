/*:root {
    --primary-color: white;
    --primary-background-color: white;
    --secondary-color: #5297FF;
    --secondary-background-color: #5297FF;
    --primary-text-color: #2d2d2d;
    --secondary-text-color: white;
    --primary-highlight-color: #5297FF;
    --secondary-highlight-color: white;
    --primary-border-color: #cccccc;
    --secondary-border-color: #cccccc;
    --alert-background-color: black;
    --background-color: #EAEDF2;
}*/


/*GENERAL*/
/*.centralized {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    text-align: center;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}*/


/* LOGIN PAGE */
/*.login {
    height: 490px;
    width: 320px;
}

    .login .cloud {
        position: absolute;
        right: 0px;
        top: 0px;
    }

    .login .graphic {
        position: absolute;
        height: 200px;
        width: 120px;
        left: 0px;
        bottom: 0px;
    }

    .login .logo {
        position: absolute;
        left: 90px;
        top: 60px;
        width: 150px;
    }

    .login .form {
        position: absolute;
        right: 50px;
        top: 120px;
    }

    .login .links {
        position: absolute;
        right: 20px;
        bottom: 10px;
    }*/


/* FORM */
/*.form-input {
    padding-left: 10px;
    font-size: 13px;
}

.form-input-icon {
    font-size: 13px;
    padding-left: 7px;
    padding-right: 7px;
    background-color: white;
    color: #5297FF;
}

.form-error {
    color: red;
    font-size: 10px;
    height: 12px;
    margin-bottom:7px;
}*/
