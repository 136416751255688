:root {
  --p-color: white;
  --p-background-color: white;
  --s-color: #5297ff;
  --s-background-color: #5297ff;
  --p-text-color: #2d2d2d;
  --s-text-color: white;
  --p-highlight-color: #5297ff;
  --s-highlight-color: white;
  --p-border-color: #cccccc;
  --s-border-color: #cccccc;
  --alert-background-color: black;
  --background-color: #f0f0f0; /*#EAEDF2;*/
  --master-color: #007bff;
  --menu-master-color: white;
  --menu-page-title: #00a44e;
  --header-master-color: darkslategray;
  /*THEME*/
  --main-color: #0f3969;
  --nav-main-background-color: #0f3969;
  --nav-main-light-background-color: #174579;
  --text-main-color: black;
  --text-main-color-selected: #0f3969;
  --text-nav-color: white;
  --text-nav-color-selected: #1aae61;
  --content-main-background-color-light: white;
  --content-main-background-color-dark: #f2f2f2;
  --content-main-background-topandbottom-border: #e5e9ec;
  --navigation-master-color: #0f3969;
  --navigation-master-color_ligth: #174579;
  --dashboard-tab-selected: #1aae61;
  /*ACTIONS*/
  --action--delete-color: crimson;
  --action--edit-color: #408103;
  /*INFO*/
  --in-progress--orange: #db7c00;
  /*INPUT*/
  --input-border-radius: 4px;
  --input-border: 1px solid #b2b2b2 !important;
  --input-font-family: "Montserrat", "Open Sans", sans-serif;
  --input-font-weight: normal !important;
  --input-font-size: 14px !important;
  --input-floatinglabel-font-size: 12px !important;
  --input-floatinglabel-font-color: #898989;
  --calendar-day-open: #174579;
  --calendar-day-approved: var(--text-nav-color-selected) /*#52b93b*/;
  --calendar-day-no-data: #d60015;
  --calendar-day-paid: #086209;
  --header-font-family: "Roboto", "Open Sans", sans-serif;
  /*STATUS*/
  --irrelevant-color: #0f3969;
  --invalid-color: crimson;
  --valid-color: #1aae61;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

body {
  background-color: white;
  font-family: var(--input-font-family) !important;
  color: #666666;
}

.p-component {
  font-family: var(--input-font-family) !important;
}

/** {
  font-family: Your font-family;
}*/
body .p-inputtext {
  height: 33px;
  min-width: 10px;
}

body .ui-multiselect {
  height: 33px;
  min-width: 10px;
}
.capitalize {
  text-transform: capitalize;
}

body .ui-multiselect .ui-multiselect-label {
  padding: 5px;
}

.uppercase {
  text-transform: uppercase;
}

.help div .ui-overlaypanel-content {
  height: 100%;
}

.help.documents div.ui-overlaypanel {
  top: 45px !important;
  left: 10% !important;
}

.help .p-dialog .p-dialog-header {
  height: 30px;
  background-color:white;
}

.help .p-dialog .p-dialog-header .p-dialog-header-icon {
  color: #a6a6a6;
}


.help.documents .p-dialog {
  top: 45px !important;
  left: 10% !important;
}

/*GENERAL*/
.centralized {
  width: 100%;
  position: absolute;
  top: 500px;
  left: 50%;
  text-align: center;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

/* LOGIN PAGE */

@media (max-width: 760px) {
  #iframeURL {
    min-height: 1500px;
  }
}

@media (max-width: 980px) {
  #iframeURL {
    min-height: 1500px;
  }
}

@media (max-width: 1200px) {
  #iframeURL {
    min-height: 1500px;
  }
}

.container-login {
  padding-top: 60px;
  /* overflow: hidden; */
  padding-left: 8%;
  padding-right: 8%;
  background-color: white;
}
.container-login .navbar-header {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.login-form .inputs {
  display: block;
  width: 100%;
  padding: 10px 20px;
  background: #fafafa;
  color: #333333;
  font-size: 13px;
  /* font-family: "Lato", sans-serif; */
  outline: none;
  border: 1px solid #e1e1e1;
  margin: 0 0 16px;
  transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  height: 50px;
}

.login-form #signin {
  background-color: #2eb061;
  border: none;
  color: #fff;
  text-decoration: none;
  text-transform: none;
  font-size: 17px;
  width: 206px;
  /* font-family: sans-serif; */
  font-weight: 400;
  height: 50px;
  border-radius: 0 !important;
  text-align: left;
}

.advertisement {
  overflow: hidden;
  padding: 7px 0 12px;
}

.contact-form-box {
  margin: 0;
  float: right;
  padding-top: 30px;
}

.opacity-holder {
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.8);
}

.nlogin {
}

.bold {
  font-weight: bold;
}

.nlogin .form-input {
  padding-left: 10px;
  font-size: 13px;
  width: 300px;
  border-radius: 0 !important;
  border: 0;
}

.nlogin .ui-inputgroup-addon {
  display: inline-block;
  height: 50px;
  vertical-align: middle;
  line-height: 50px;
  border-radius: 0 !important;
  border: 0;
  margin-top: -1px;
}

.nlogin .list-unstyled {
  list-style: none;
}

.nlogin .list-unstyled li {
  height: 70px;
}

.nlogin .headingholder-title {
  position: absolute;
  top: 20px;
  left: 200px;
  /* font-family: sans-serif; */
  font-size: 60px;
  font-weight: bolder;
  color: white;
}

.nlogin .headingholder-logo {
  position: absolute;
  top: 45px;
  right: 50px;
}

.nlogin .rightsreserved {
  width: 100%;
  position: absolute;
  top: 650px;
  left: 50%;
  text-align: center;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  /* font-family: sans-serif; */
}

.nlogin .headingholder-title .headingholder1 {
}

.nlogin .headingholder-title .headingholder2 {
  color: springgreen;
}

.nlogin .headingholder-title .headingholder3 {
  font-weight: 300;
}

.nlogin .headingholder-title .headingholder4 {
  font-size: 30px;
}

.nlogin .headingholder-logo .logo {
  left: 500px;
  font-size: large;
  /* font-family: sans-serif; */
  /* font-weight: bold; */
  color: white;
  width: 200px;
  margin-right: 20px;
}

.nlogin .centralized {
  width: 100%;
  position: absolute;
  top: 400px;
  left: 50%;
  text-align: center;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.nlogin .centralized .transparentframe {
  width: 430px;
  margin: auto;
  z-index: -100;
  height: 380px;
  background-color: rgba(23, 69, 120, 0.6);
  padding: 20px;
}

.nlogin .centralized .transparentframe .signin {
  color: white;
  font-size: 24px;
  font-weight: bold;
  /* font-family: sans-serif; */
  text-align: left;
}

.nlogin .centralized .registeredonly {
  color: white;
  font-size: 16px;
  /* font-family: sans-serif; */
  text-transform: uppercase;
  text-align: left;
  cursor: pointer;
}

.nlogin .centralized .transparentframe .button {
  background-color: var(--text-nav-color-selected);
  border: none;
  color: white;
  text-decoration: none;
  text-transform: none;
  font-size: 17px;
  width: 326px;
  /* font-family: sans-serif; */
  font-weight: normal;
  height: 50px;
  border-radius: 0 !important;
  text-align: left;
}

.nlogin .centralized .transparentframe .forgotpassword {
  color: white;
  font-size: 16px;

  cursor: pointer;
}
.nlogin .form-error {
  position: relative;
  text-align: left;
  margin-left: 38px;
}

.nsignup .form-error{
    position: relative;
    text-align:left;
}

body {
  background-size: cover;
}

.forgotpass .p-dialog .p-dialog-content {
  border: none;
  background-color: lightgray;
}

.forgotpass .button {
  background-color: var(--text-nav-color-selected);
  border: none;
  color: white;
  text-decoration: none;
  text-transform: none;
  font-size: 17px;
  width: 326px;

  font-weight: normal;
  height: 50px;
  border-radius: 0 !important;
  text-align: left;
}

.calendar-range {
  max-width: 100% !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.calendar-alarm {
  padding-top: 5px;
}

.calendar-alarm .badge {
  position: absolute;
  top: 10px;
  padding: 10px 10px;
  border-radius: 50%;
  background: red;
  color: white;
  transform: translate(-50%, -50%);
  animation: jump 1.1s infinite;
}

.system-message-badge {
  position: fixed;
  top: 20px;
  border-radius: 50%;
  padding: 2px 2px;
  background: red;
  color: white;
  transform: translate(-50%, -50%);
  animation: jump 1.1s infinite;
}

@keyframes jump {
  0% {
    transform: translate(-50%, -50%) scale(1.1, 0.9);
  }

  50% {
    transform: translate(-50%, -110%) scale(1, 1);
  }

  100% {
    transform: translate(-50%, -50%) scale(1.1, 0.9);
  }
}

.calendar-container {
  left: 50%;
  position: absolute;
}
.calendar-container.normal {
  margin-left: -116px;
  width: 200px;
}

.calendar-container.wide {
  margin-left: -138px;
  width: 240px;
}

.calendar-container.wide-one-date {
  width: 220px;
  margin-left: -122px;
}

.calendar-footer-container {
  position: relative;
  margin-bottom: 20px;
  position: relative;
  height: 55px;
}

.calendar-footer-container .calendar-footer {
  height: 183%;
  position: absolute;
  left: 50%;
  margin-top: -20px;
  z-index: 1;
}

.calendar-footer-container .calendar-footer.normal {
  width: 100px;
  margin-left: -56px;
}

.calendar-footer-container .calendar-footer.wide {
  width: 220px;
  margin-left: -116px;
}

.calendar-footer-container .calendar-footer.wide .left {
  width: 60%;
}

.calendar-footer-container .calendar-footer.wide .right {
  width: 60%;
}

.calendar-footer-container .calendar-footer .left {
  background: white;
  height: 95%;
  width: 45%;
  margin-left: -22%;
  border-radius: 0% 15% 15% 0%;
  float: left;
}

.calendar-footer-container .calendar-footer .right {
  background: white;
  height: 95%;
  width: 45%;
  margin-right: -22%;
  float: right;
  border-radius: 15% 0% 0% 15%;
}

.calendar-footer-container .calendar-footer .calendar {
  position: absolute;
  padding: 5px;
  left: 23%;
  top: 7%;
}

.accordion-title {
  font-weight: normal;
  position: absolute;
  left: 50%;
  text-align: center;
}

.landscape {
  display: block;
  width: 100vh;
  height: 100vw;
  /* border: none; */
  transform: translateY(100vh) rotate(-90deg);
  transform-origin: top left;
}

.logo-spiner-container {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  /* background: transparent; */
  background-color: #454545b8;
  position: fixed;
  z-index: 5000;
}
.dot-container {
  width: 400px;
  height: 200px;
  display: grid;
  place-items: center;
}

.logo-spiner-container .dot-loader {
  position: fixed;
  height: 150px;
  top: 60%;
  left: 48%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  z-index: 5000;
}

.logo-spiner-container .cycle-spiner {
  position: fixed;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.logo-spiner-container .cycle-spiner .cycle {
  position: absolute;
  width: 50px;
  height: 50px;
  background: var(--nav-main-background-color);
  border-radius: 50%;
  transform: translate(-50%, -50%);
  animation: calc(1.6s * (var(--start))) linear normal none infinite running
    cyclezoom;
}
.shift-adjustment .p-datatable-scrollable .p-datatable-thead,
.p-datatable-scrollable .p-datatable-tbody,
.p-datatable-scrollable .p-datatable-tfoot {
  display: table-header-group !important;
}

.shift-adjustment .p-datatable-scrollable .p-datatable-thead > tr,
.p-datatable-scrollable .p-datatable-tbody > tr,
.p-datatable-scrollable .p-datatable-tfoot > tr {
  display: table-row !important;
}

.shift-adjustment .p-datatable-scrollable .p-datatable-thead {
  z-index: 2 !important ;
}

.shift-adjustment .p-datatable-scrollable .p-datatable-thead > tr > th,
.p-datatable-scrollable .p-datatable-tbody > tr > td,
.p-datatable-scrollable .p-datatable-tfoot > tr > td {
  display: table-cell !important;
}

.shift-adjustment .p-datatable-scrollable .p-datatable-thead,
.p-datatable-scrollable .p-datatable-tbody,
.p-datatable-scrollable .p-datatable-tfoot {
  display: table-header-group !important;
}

@keyframes cyclezoom {
  0% {
    transform: scale(1, 1);
    opacity: 1;
  }

  50% {
    transform: scale(calc(4 * (var(--duration))), calc(4 * (var(--duration))));
    opacity: 0;
  }

  100% {
    transform: scale(1, 1);
    opacity: 0;
  }
}

.logo-spiner {
  transform: translate(-50%, -50%);
  animation: logzoom 1.6s infinite;
  position: fixed; /* or absolute */
  top: 50%;
  left: 50%;
  margin-left: -50px;
  margin-top: -50px;
  z-index: 2;
}

.chat-spiner {
  transform: translate(-50%, -50%);
  animation: logzoom 1.6s infinite;
  position: fixed; /* or absolute */
  bottom: 320px;
  right: 210px;
  margin-left: -50px;
  margin-top: -50px;
  z-index: 2;
}

@keyframes logzoom {
  0% {
    transform: scale(1, 1);
    opacity: 0.4;
  }

  50% {
    transform: scale(1.2, 1.2);
    opacity: 1;
  }

  100% {
    transform: scale(1, 1);
    opacity: 0.4;
  }
}

.change-password{

}

.change-password .form-input {
  padding-left: 10px;
  font-size: 13px;
  width: 300px;
  border-radius: 0 !important;
  border: 0;
}

.change-password .ui-inputgroup-addon {
  display: inline-block;
  height: 50px;
  vertical-align: middle;
  line-height: 50px;
  border-radius: 0 !important;
  border: 0;
  margin-top: -1px;
}

.change-password .list-unstyled {
  list-style: none;
}

.change-password .list-unstyled li {
  height: 70px;
}

.change-password .pass-container {
  width: 100%;
  position: relative;
  text-align: center;
}
.change-password .pass-container .p-inputtext {
  height: 50px;
  width: 275px;
  border: 0px;
  border-radius: 0 !important;
}

.change-password .pass-container .transparentframe {
  margin: auto;
  z-index: -100;
  background-color: rgba(23, 69, 120, 0.6);
  padding-top: 4px;
}

.nsignup {
}

.nsignup .form-input {
  padding-left: 10px;
  font-size: 13px;
  width: 300px;
  border-radius: 0 !important;
  border: 0;
}

.nsignup .p-dropdown .p-dropdown-trigger {
  border-radius: 0;
}

.nsignup .ui-inputgroup-addon {
  display: inline-block;
  height: 50px;
  vertical-align: middle;
  line-height: 50px;
  border-radius: 0 !important;
  border: 0;
  margin-top: -1px;
}

.nsignup .list-unstyled {
  list-style: none;
}

.nsignup .list-unstyled li {
  height: 70px;
}

.nsignup .headingholder-title {
  position: absolute;
  top: 20px;
  left: 200px;

  font-size: 60px;
  font-weight: bolder;
  color: white;
}

.nsignup .headingholder-logo {
  position: absolute;
  top: 45px;
  right: 50px;
}

.nsignup .rightsreserved {
  width: 100%;
  position: absolute;
  top: 920px;
  left: 50%;
  text-align: center;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

.nsignup .headingholder-title .headingholder1 {
}

.nsignup .headingholder-title .headingholder2 {
  color: springgreen;
}

.nsignup .headingholder-title .headingholder3 {
  font-weight: 300;
}

.nsignup .headingholder-title .headingholder4 {
  font-size: 30px;
}

.nsignup .headingholder-logo .logo {
  left: 500px;
  font-size: large;
  /* font-weight: bold; */
  color: white;
  width: 200px;
  margin-right: 20px;
}

.nsignup .centralized {
  width: 100%;
  position: absolute;
  top: 500px;
  left: 50%;
  text-align: center;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.nsignup .centralized .p-inputtext {
  height: 50px;
  width: 300px;
  border: 0px;
  border-radius: 0 !important;
}

.nsignup .centralized .transparentframe {
  width: 430px;
  margin: auto;
  z-index: -100;
  height: 780px;
  background-color: rgba(23, 69, 120, 0.6);
  padding: 20px;
}

.nsignup .centralized .transparentframe .signin {
  color: white;
  font-size: 24px;
  font-weight: bold;
  text-align: left;
}

.nsignup .centralized .registeredonly {
  color: white;
  font-size: 16px;
  text-transform: uppercase;
  text-align: left;
  cursor: pointer;
}

.nsignup .centralized .transparentframe .button {
  background-color: var(--text-nav-color-selected);
  border: none;
  color: white;
  text-decoration: none;
  text-transform: none;
  font-size: 17px;
  width: 326px;
  font-weight: normal;
  height: 50px;
  border-radius: 0 !important;
  text-align: left;
}

.nsignup .centralized .transparentframe .forgotpassword {
  color: white;
  font-size: 16px;
  cursor: pointer;
}
.nsignup .form-input-icon {
  color: gray;
}

.login-background {
  /* background-color: black !important; */
  /* margin-left: -60px;
  margin-top: -48px; */
  position: relative !important;
  top: 0px !important;
}

.login-background-admin {
  background-color: black !important;
  margin-left: -60px;
  top: 0px !important;
}

.change-pass-eye {
  position: absolute;
  margin-right: 15px;
  padding-right: 15px;
  right: 0px;
  margin-top: 15px;
}

.navigation-master-color {
  color: var(--navigation-master-color) !important;
}

.navigation-master-background {
  background-color: var(--navigation-master-color) !important;
}

.content-main-background-color-dark {
  background-color: var(--content-main-background-color-dark) !important;
}

.side-background {
  background-color: white !important;
}

.text-extrasmall {
  font-size: 12px;
}

.text-small {
  font-size: 13px;
}

.text-medium {
  font-size: 14px;
}

.text-large {
  font-size: 20px;
}

.text-extralarge {
  font-size: 32px;
}

.text-xxl {
  font-size: 48px;
}

.left-text {
  text-align: left;
}

.right-text {
  text-align: right;
}

.central-text {
  text-align: center;
}

.bold {
  font-weight: bold;
}

.italic {
  font-style: italic;
}

.color-erorr {
  color: red !important;
}

.pointer-width-30 {
  cursor: pointer;
  width: 30% !important;
}

.no-wrap {
  white-space: nowrap !important;
}

.new-line {
  white-space: pre-wrap;
}

.color-valid {
  color: #54ba3b !important;
}

.color-draft {
  color: #007ad9 !important;
}
.color-sent {
  color: #e4a800 !important;
}

.width-auto {
  width: auto !important;
}

.stretched {
  width: 100%;
  height: 100%;
}

.aligned-top {
  vertical-align: top;
}

.break-line {
  height: 10px;
  display: table-row;
}

.title {
  color: #5297ff;
  font-size: 20px;
  width: 100%;
  margin: 0px;
  margin-bottom: 20px;
  border-bottom: 0px solid #999999;
}

.subtitle {
  color: #5297ff;
  font-size: 18px;
  width: 100%;
  margin: 0px;
  margin-bottom: 10px;
  border-bottom: 0px;
}

.control-title {
  font-size: 20px;
  font-weight: bold;
  color: var(--nav-main-light-background-color);
  background-color: transparent;
  height: 50px;
  text-align: center;
}

.uppercase {
  text-transform: uppercase;
}

.counter {
  position: absolute;
  right: -6px;
  top: -5px;
  background-color: var(--alert-background-color);
  color: white;
  font-size: 10px;
  height: 15px;
  width: 18px;
  border-radius: 7px;
  text-align: center;
  padding-left: 2px;
  padding-right: 2px;
}

.counter-large {
  color: #5297ff;
  font-size: 36px;
  text-align: center;
  width: 100%;
  vertical-align: middle;
  font-weight: bold;
}

.icon-medium {
  width: 48px;
  height: 48px;
}

.icon-large {
  width: 86px;
  height: 86px;
}

.margin-normal {
  margin: 5px;
}

.margin-normal-top {
  margin-top: 5px;
}

.margin-medium {
  margin: 10px;
}

.margin-medium-top {
  margin-top: 10px;
}

.padding-normal {
  padding: 5px;
}

.padding-medium {
  padding: 10px;
}

.padding-medium-bottom {
  padding-bottom: 10px;
}

.pointer {
  cursor: pointer;
}

.border-bottom {
  border-bottom: 1px solid #5297ff;
}

.borderless {
  border-width: 0px;
  background-color: transparent;
}

.border-all {
  border: 1px solid #5297ff;
}

.selected {
  color: #fff !important;
  background-color: #0094ff !important;
}

.list-unstyled {
  list-style: none;
  padding-left: 0px;
}

.list-inline {
  display: inline-flex;
}

.list-inline li {
  padding-right: 5px;
}

.display-flex {
  display: flex !important;
}

.display-none {
  display: none !important;
}

.pointer-events-none {
  pointer-events: none;
}
/* TABLES */
.header-extrasmall {
  min-width: 60px;
  font-size: 12px;
}

.row-alternate {
  border-top: 1px solid #f0f0f0;
  background-color: #f6f6f6;
}

.row-regular {
  border-top: 1px solid #f0f0f0;
}

.table-row:hover {
  background-color: #e2e2e2 !important;
  color: white !important;
}

.invalid-row {
  border-left: 10px solid red;
  background-color: rgba(255, 0, 0, 0.3) !important;
}

.selected-row {
  border-top: 2px solid var(--navigation-master-color) !important;
  border-bottom: 2px solid var(--navigation-master-color) !important;
  border-left: 2px solid var(--navigation-master-color) !important;
  border-right: 1px solid var(--navigation-master-color) !important;
}

.invalid-scroll-row td:first-child {
  border-left: 10px solid red !important;
}

.p-datatable-scrollable-body .frozen .p-datatable-tbody tr > td {
  border: 0px !important;
}

.p-datatable-frozen-view .p-datatable-scrollable-footer {
  display: none;
}

.overflow-hidden {
  overflow: hidden !important;
}

.warning-row {
  border-left: 10px solid var(--in-progress--orange) !important;
}

.added-row {
  border-left: 10px solid lightblue !important;
}

.height_33 {
  height: 33px;
}

th span {
  display: flex;
}

th span p-sorticon {
  align-self: center;
}

.gs-tooltip {
  background-color: white;
  position: absolute;
  border-radius: 10px;
  font-size: 14px;
  pointer-events: none;
  border: 3px solid;
  z-index: 50000;
}

.gs-tooltip .gs-tooltip-container {
  min-height: 40px;
  border-radius: 10px;
  padding: 10px;
  color: white;
}

.gs-tooltip .gs-tooltip-arrow-up {
  position: absolute;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid;
  top: -10px;
  pointer-events: none;
}

.gs-tooltip .gs-tooltip-arrow-down {
  position: absolute;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid;
  bottom: -10px;
  pointer-events: none;
}

.gs-tooltip-info {
  background-color: var(--navigation-master-color) !important;
  border-color: var(--navigation-master-color) !important;
  color: var(--navigation-master-color) !important;
}

.gs-tooltip-info .gs-tooltip-arrow-up {
  border-bottom-color: var(--navigation-master-color) !important;
}
.gs-tooltip-info .gs-tooltip-arrow-down {
  border-bottom-color: var(--navigation-master-color) !important;
}

.gs-tooltip-info .gs-tooltip-container {
  border-bottom-color: var(--navigation-master-color) !important;
}

.gs-tooltip-warning {
  background-color: var(--in-progress--orange) !important;
  border-color: var(--in-progress--orange) !important;
  color: var(--in-progress--orange) !important;
}

.gs-tooltip-warning .gs-tooltip-arrow-up {
  border-bottom-color: var(--in-progress--orange) !important;
}

.gs-tooltip-warning .gs-tooltip-arrow-down {
  border-bottom-color: var(--in-progress--orange) !important;
}

.gs-tooltip-error {
  background-color: white !important;
  border-color: var(--action--delete-color) !important;
  color: var(--action--delete-color) !important;
}

.gs-tooltip-error .gs-tooltip-arrow-up {
  border-bottom-color: var(--action--delete-color) !important;
}

.gs-tooltip-error .gs-tooltip-arrow-down {
  border-bottom-color: var(--action--delete-color) !important;
}

.gs-tooltip-error .gs-tooltip-container {
  color: var(--action--delete-color) !important;
}

.sticky-header {
  position: sticky;
  overflow: hidden !important;
  top: 0;
}

/* FORM */
.form-input {
  padding-left: 10px;
  font-size: 13px;
}

.form-input-icon {
  font-size: 13px;
  padding-left: 7px;
  padding-right: 7px;
  background-color: #ebebeb;
  color: #5297ff;
  border: none;
}

.form-error {
  color: red;
  font-size: 10px;
  position: absolute;
  right: 10px;
  top: 3px;
}
.error-color {
  color: red !important;
}
.error-backgroundcolor {
  background-color: red !important;
}

.form-valid-icon {
  position: absolute;
  right: 5px;
  top: 5px;
}
.form-valid-icon.with-icon {
  right: 35px !important;
}

.form-property {
  padding-top: 22px;
}

.property-tooltip {
  padding-top: 5px;
}

.no-padding-right {
  padding-right: 0px !important;
}

.property-readonly {
  color: #858585 !important;
}

.property-invalid {
  border: 2px solid red !important;
}

.multiselect-property-invalid .p-multiselect {
  border: 2px solid red !important;
}

.dropdown-property-invalid .p-dropdown {
  border: 2px solid red !important;
}
/* .dropdown-property-invalid .p-dropdown .p-dropdown-label {
  border: 2px solid red !important;
} */

.autocomplete-property-invalid .p-autocomplete .p-autocomplete-input {
  border: 2px solid red !important;
}

.daterange-property-invalid .p-calendar.p-calendar-w-btn .p-inputtext {
  border: 2px solid red !important;
}
.daterange-property-invalid .p-calendar.p-calendar-w-btn .ui-inputtext {
  border: 2px solid red !important;
}
.gs-autocomplete-icon {
  margin-top: 4px;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 8px solid #f8f9fa;
}

.p-accordion
  .p-accordion-header:not(.p-disabled)
  .p-accordion-header-link:focus {
  box-shadow: none;
}

p-dropdown.property-invalid:not(form) > div {
  border: 2px solid #d40000;
}

p-spinner.error-spinner span {
  border: red 2px solid;
  margin: -3px -3px -3px -4px;
}

.calendar-property-invalid .p-calendar .p-inputtext {
  border: 2px solid red !important;
}
.calendar-property-invalid .p-calendar .ui-inputtext {
  border: 2px solid red !important;
}

.currency-property-invalid .p-inputtext {
  border: 2px solid red !important;
}
.currency-property-invalid .ui-inputtext {
  border: 2px solid red !important;
}

.property-invalid .p-inputtext.p-inputmask {
  border: 2px solid red !important;
}

.p-inputmask {
  width: 100% !important;
}

p-inputmask.property-invalid {
    border: none !important;
}
.form-textarea {
  width: 100%;
  /*position: absolute;*/
  height: auto !important;
}

.content-title-bar {
  background-color: var(--nav-main-light-background-color);
  color: white;
  height: 40px;
  line-height: 40px;
  padding-left: 10px;
  font-family: var(--input-font-family);
  font-weight: bold;
  font-size: 16px;
  text-transform: uppercase;
  font-family: var(--header-font-family) !important;
}

.buttons-title-bar {
  background-color: var(--nav-main-light-background-color);
  color: white;
  height: 45px;
  line-height: 40px;
  margin-top: 20px;
  font-family: var(--input-font-family);
  font-weight: bold;
  font-size: 16px;
  text-transform: uppercase;
  text-align: center;
  width: 100%;
  bottom: 0;
}

.buttons-title-bar .p-button {
  height: 35px;
}

.content-title-label {
  background-color: var(--content-main-background-color-light);
  color: var(--nav-main-light-background-color);
  height: 20px;
  line-height: 20px;
  font-family: var(--input-font-family);
  font-weight: bold;
  font-size: 16px;
  text-transform: uppercase;
  border-radius: 10px;
}

.payroll-export-button-bar {
  right: 15px;
  position: absolute;
  bottom: 25px;
  left: 15px;
  width: auto;
}

/*WIZARD DASHBOARD*/
.wizard-dashboard {
  margin: 10px;
  padding: 10px;
  color: #007ad9;
  background-color: white;
  border-radius: 10px;
  box-shadow: #6c757d 10px 5px;
  border: 1px solid var(--navigation-master-color);
  min-height: 110px;
}

.wizard-dashboard .wizard-dashboard-header {
  border: 1px solid  #005b9f;
  background-color: #174579;
  color: white;
  padding: 3px;
  margin: -5px;
  border-radius: 5px;
  padding-right: 25px;
}

.wizard-dashboard .wizard-dashboard-header .wizard-dashboard-header-infoicon {
  position: absolute;
  right: 10px;
  top: 8px;
  color: white;
}

.wizard-dashboard .wizard-dashboard-content {
}
.wizard-dashboard .wizard-dashboard-content .wizard-dashboard-content-icon {
  position: absolute;
  right: 5px;
  bottom: 10px;
  color: #007ad9;
}

.wizard-dashboard .wizard-dashboard-content .wizard-dashboard-valid {
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #10c310;
  box-shadow: #10c31085 1px 1px 4px 4px;
  bottom: 10px;
  left: 10px;
}

.wizard-dashboard .wizard-dashboard-content .wizard-dashboard-progress {
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #ff1313;
  box-shadow: #ff00006e 1px 1px 4px 4px;
  bottom: 10px;
  left: 10px;
}

.master-color {
  color: var(--master-color) !important;
}

.nav-main-light-background-color {
  color: var(--nav-main-light-background-color);
}

.app-header {
  width: 100%;
  height: 50px;
  background-color: white;
  z-index: 9;
  position: fixed;
  border-bottom: var(--input-border);
  /*box-shadow: 0px 5px 14px 0px;*/
  color: var(--header-master-color);
}

.app-header.minimize {
  left: 60px;
  padding-right: 60px;
  color: var(--header-master-color);
}

.app-header.maximize {
  left: 220px;
  padding-right: 220px;
}

.app-header .header-container {
  width: 100%;
  margin: 3px;
  height: 32px;
}

.app-header .button-header {
  cursor: pointer;
  text-align: center;
  border: 1px solid var(--s-border-color);
  border-radius: 4px;
  padding: 1px;
  margin-right: 5px;
  height: 30px;
  width: 30px;
  background-color: var(--s-background-color);
  color: var(--s-text-color);
  font-size: 16px;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.4);
}

.app-header .button-header-active {
  cursor: pointer;
  text-align: center;
  border: 1px solid #5297ff;
  border-radius: 4px;
  padding: 1px;
  margin-right: 5px;
  height: 30px;
  width: 30px;
  background-color: orangered;
  color: white;
  font-size: 16px;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.4);
}

.app-header .header-search {
  max-width: 160px;
}

.app-content {
  position: absolute;
  right: 0px;
  top: 48px;
  left: 0px;
  bottom: 1px;
  overflow-y: auto;
  background-color: var(--background-color);
}
.content-item {
  position: relative;
  margin: 10px 10px 20px 10px;
  padding: 10px 10px 10px 10px;
  background: var(--menu-master-color);
  min-height: 300px;
  border-radius: 10px;
}
.content-item.transparent {
  background: transparent !important;
}

.content-item.transparent .content-table {
  background: var(--menu-master-color);
  padding: 10px;
}

.content-item.transparent.form .content-form {
  background: var(--menu-master-color);
  padding: 10px;
}

.content-item.transparent .content-card {
  background: var(--menu-master-color);
  margin: 10px;
  padding: 5px;
}

.app-footer {
  background-color: white;
  border-top: 1px solid #cccccc;
  position: absolute;
  height: 40px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  position: fixed;
  padding-top: 4px;
  text-align: center;
}

.app-footer .footer-text {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
  color: var(--navigation-master-color);
}

.navigation {
  height: 100%;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: var(--navigation-master-color);
  color: var(--menu-master-color);
  overflow-x: hidden;
  border-right: 1px solid var(--background-color);
  box-shadow: #80808040 2px 0px 5px 0px;
  width: 255px;
  overflow-y: hidden;
}
.navigation .nav-heading {
  height: 50px;
  line-height: 50px;
  margin-bottom: 10px;
}

.navigation .menu-items {
  width: 50px;
  position: absolute;
  height: 100%;
  line-height: 50px;
  height: 50px;
}

.navigation .menu-items .icon {
  border-radius: 50%;
  background-color: transparent;
  color: var(--content-main-background-color-light);
  text-align: center;
  width: 30px;
  height: 30px;
  padding: 3px;
  margin: 2px;
}
.navigation .menu-items .navigation_menu-items_selected {
  background: var(--navigation-master-color_ligth);
  text-align: center;
  border-right: 4px solid var(--text-nav-color-selected);
}

.navigation .pages {
  width: 205px;
  background: var(--navigation-master-color_ligth);
  position: absolute;
  height: 100%;
  margin-left: 50px;
}

.navigation .pages .navigation-pages-page {
  color: var(--text-nav-color);
  text-transform: uppercase;
  font-size: 12px;
  font-weight: normal;
  /*height: 36px;*/
  padding-bottom:20px;
}
.navigation .pages .navigation-pages-page:hover {
  color: var(--text-nav-color-selected);
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
}
.navigation .pages .page-selected {
  color: var(--text-nav-color-selected) !important;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: normal;
}

.navigation .bars {
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.pointer {
  cursor: pointer;
  outline: 0 !important;
}

.center {
  text-align: center;
}

.right {
  text-align: right;
}

.left {
  text-align: left;
}

#loader {
  bottom: 0;
  height: 175px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  width: 175px;
}

#loader {
  bottom: 0;
  height: 175px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  width: 175px;
}

#loader .dot {
  bottom: 0;
  height: 100%;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  width: 87.5px;
}

#loader .dot::before {
  border-radius: 100%;
  content: "";
  height: 87.5px;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transform: scale(0);
  width: 87.5px;
}

#loader .dot:nth-child(7n + 1) {
  transform: rotate(45deg);
}

#loader .dot:nth-child(7n + 1)::before {
  animation: 0.8s linear 0.1s normal none infinite running load;
  background: #5297ff none repeat scroll 0 0;
}

#loader .dot:nth-child(7n + 2) {
  transform: rotate(90deg);
}

#loader .dot:nth-child(7n + 2)::before {
  animation: 0.8s linear 0.2s normal none infinite running load;
  background: #5297ff none repeat scroll 0 0;
}

#loader .dot:nth-child(7n + 3) {
  transform: rotate(135deg);
}

#loader .dot:nth-child(7n + 3)::before {
  animation: 0.8s linear 0.3s normal none infinite running load;
  background: #5297ff none repeat scroll 0 0;
}

#loader .dot:nth-child(7n + 4) {
  transform: rotate(180deg);
}

#loader .dot:nth-child(7n + 4)::before {
  animation: 0.8s linear 0.4s normal none infinite running load;
  background: #5297ff none repeat scroll 0 0;
}

#loader .dot:nth-child(7n + 5) {
  transform: rotate(225deg);
}

#loader .dot:nth-child(7n + 5)::before {
  animation: 0.8s linear 0.5s normal none infinite running load;
  background: #5297ff none repeat scroll 0 0;
}

#loader .dot:nth-child(7n + 6) {
  transform: rotate(270deg);
}

#loader .dot:nth-child(7n + 6)::before {
  animation: 0.8s linear 0.6s normal none infinite running load;
  background: #5297ff none repeat scroll 0 0;
}

#loader .dot:nth-child(7n + 7) {
  transform: rotate(315deg);
}

#loader .dot:nth-child(7n + 7)::before {
  animation: 0.8s linear 0.7s normal none infinite running load;
  background: #5297ff none repeat scroll 0 0;
}

#loader .dot:nth-child(7n + 8) {
  transform: rotate(360deg);
}

#loader .dot:nth-child(7n + 8)::before {
  animation: 0.8s linear 0.8s normal none infinite running load;
  background: #5297ff none repeat scroll 0 0;
}

#loader .loading {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  bottom: -40px;
  height: 20px;
  left: 0;
  position: absolute;
  right: 0;
  width: 180px;
}

@keyframes load {
  100% {
    opacity: 0;
    transform: scale(1);
  }
}

@keyframes load {
  100% {
    opacity: 0;
    transform: scale(1);
  }
}

.spinner-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 998;
  app-spinner {
    width: 6rem;
    height: 6rem;
  }
}

.spinner-message {
  text-align: center;
}

.list-unstyled {
  list-style: none;
  padding-left: 0px;
}

.list-unstyled li {
  height: 30px;
  padding-right: 5px;
}

.list-inline {
  display: inline-flex;
}

.list-inline li {
  padding-right: 5px;
}

.display-block {
  display: block;
}

.web-kit-right {
  text-align: -webkit-right;
}

.text-l {
  font-size: 16px;
}

.text-xl {
  font-size: 18px;
}

.text-xxl {
  font-size: 20px;
}

.text-m {
  font-size: 14px;
}

.text-s {
  font-size: 12px;
}

.text-s-important {
  font-size: 12px !important;
}

.text-xs {
  font-size: 10px;
}

.white {
  color: white !important;
}
.white-background {
  background-color: white !important;
}

.edit-background {
  background-color: var(--action--edit-color);
}

.edit-background-important {
  background-color: var(--action--edit-color) !important;
}

.edit-background-important:hover {
  background-color: var(--nav-main-background-color) !important;
}

.delete-background-important {
  background-color: var(--action--delete-color) !important;
}
.delete-background-important:hover {
  background-color: var(--nav-main-background-color) !important;
}

.customer-content-tab {
  margin-top: 10px;
  margin-bottom: 10px;
}
  .customer-content-tab .tab-header {
    display: -webkit-box;
    /*height: 34px;*/
    margin-bottom: -16px;
    overflow-x: auto;
  }
.customer-content-tab .tab-header .tab-item {
  padding: 5px 10px 5px 10px;
  margin-right: 2px;
  min-width: 110px;
  height: 40px;
  color: var(--nav-main-background-color);
  opacity: 0.5;
  background-color: var(--content-main-background-color-light);
  border: var(--input-border);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.customer-content-tab .tab-header .tab-item.tab-selected {
  border: var(--input-border);
  border-bottom: 1px solid var(--content-main-background-color-light);
  opacity: 1;
  /*color: var(--text-nav-color-selected);
      background-color: var(--nav-main-light-background-color);*/
  background-color: var(--content-main-background-color-dark);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.customer-content-tab .tab-content {
  background-color: white;
  margin-top: 15px;
  border: 1px solid #c8c8c8;
}

.dirty {
  width: 8px;
  height: 8px;
  background-color: red;
  border-radius: 50%;
}

.content-steps {
  right: 20px;
  float: right;
  position: absolute;
  margin-top: 16px;
}

.content-steps .content-step-current {
  border: 1px SOLID lightgray;
  padding: 7px;
  background-color: #0f3969;
  color: white;
  border-radius: 4px;
}

.content-steps .content-step-nextPrev {
  border: 1px solid lightgray;
  /*padding: 4px;*/
  padding: 8px;
  background-color: lightgray;
  border-radius: 4px;
}

.text-side-color {
  color: var(--text-side-color) !important;
}

.text-side-color {
  color: var(--text-side-color) !important;
}

body .ui-progressbar .ui-progressbar-label {
  color: var(--text-nav-color);
}

/*--nav-main-light-background-color*/
body .ui-progressbar .ui-progressbar-value {
  background-color: var(--action--edit-color);
}

body .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background-color: var(--action--edit-color);
}

.p-inputswitch .p-inputswitch-slider:before {
  background-color: white !important;
}

body .p-datatable .p-sortable-column.p-highlight {
  background-color: var(--nav-main-light-background-color);
}

body .p-datatable > tr {
  display: table-row !important;
}
body .p-datatable > tr > th {
  display: table-cell !important;
}
body .p-datatable > tr > td {
  display: table-cell !important;
}

body .p-component-overlay {
  background-color: rgb(0 0 0 / 61%);
}

input:focus ~ .floating-label {
  top: 6px;
  bottom: 12px;
  left: 20px;
  font-size: 11px;
  opacity: 1;
}

.floating-label {
  position: absolute;
  pointer-events: none;
  left: 20px;
  top: 18px;
  text-transform: uppercase;
  transition: 0.2s ease all;
  color: #b2b2b2;
}

.ribbon {
  width: 50px;
  height: 50px;
  overflow: hidden;
  position: absolute;
}

.ribbon::before,
.ribbon::after {
  position: absolute;
  z-index: -1;
  content: "";
  display: block;
  border: 5px solid #2980b9;
}

.ribbon span {
  position: absolute;
  display: block;
  width: 80px;
  padding: 3px 0;
  /*background-color: #3498db;*/
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  color: #fff;
  font-size: 8px;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  text-transform: uppercase;
  text-align: CENTER;
}

/* top left*/
.ribbon-top-left {
  top: -5px;
  left: -5px;
}

.ribbon-top-left::before,
.ribbon-top-left::after {
  border-top-color: transparent;
  border-left-color: transparent;
}

.ribbon-top-left::before {
  top: 0;
  right: 0;
}

.ribbon-top-left::after {
  bottom: 0;
  left: 0;
}

.ribbon-top-left span {
  right: -10px;
  top: 12px;
  transform: rotate(-45deg);
}

/* top right*/
.ribbon-top-right {
  top: -10px;
  right: -10px;
}

.ribbon-top-right::before,
.ribbon-top-right::after {
  border-top-color: transparent;
  border-right-color: transparent;
}

.ribbon-top-right::before {
  top: 0;
  left: 0;
}

.ribbon-top-right::after {
  bottom: 0;
  right: 0;
}

.ribbon-top-right span {
  left: -25px;
  top: 30px;
  transform: rotate(45deg);
}

/* bottom left*/
.ribbon-bottom-left {
  bottom: -10px;
  left: -10px;
}

.ribbon-bottom-left::before,
.ribbon-bottom-left::after {
  border-bottom-color: transparent;
  border-left-color: transparent;
}

.ribbon-bottom-left::before {
  bottom: 0;
  right: 0;
}

.ribbon-bottom-left::after {
  top: 0;
  left: 0;
}

.ribbon-bottom-left span {
  right: -25px;
  bottom: 30px;
  transform: rotate(225deg);
}

/* bottom right*/
.ribbon-bottom-right {
  bottom: -10px;
  right: -10px;
}

.ribbon-bottom-right::before,
.ribbon-bottom-right::after {
  border-bottom-color: transparent;
  border-right-color: transparent;
}

.ribbon-bottom-right::before {
  bottom: 0;
  left: 0;
}

.ribbon-bottom-right::after {
  top: 0;
  right: 0;
}

.ribbon-bottom-right span {
  left: -25px;
  bottom: 30px;
  transform: rotate(-225deg);
}

.marker-valid {
  background-color: forestgreen;
}

.marker-valid:hover {
  background-color: forestgreen;
}
.marker-error {
  background-color: red;
}

.marker-error:hover {
  background-color: red;
}

.marker-notInUse {
  background-color: grey;
}
.marker-notInUse-text {
  color: grey;
}

/*--action--edit-color*/
body .p-button {
  background-color: var(--action--edit-color);
  border: 0px;
  text-transform: uppercase;
  font-weight: 600;
}

body .closebtn.p-button {
  background-color: darkgray;
  border: 0px;
  text-transform: uppercase;
  font-weight: 600;
}

body .undo.p-button {
  background-color: var(--action--delete-color);
  border: 0px;
  text-transform: uppercase;
  font-weight: 600;
}
body .edit.p-button {
  background-color: var(--nav-main-light-background-color);
  border: 0px;
  text-transform: uppercase;
  font-weight: 600;
}

body .prevnext.p-button {
  background-color: var(--content-main-background-color-light);
  color: var(--nav-main-light-background-color);
  border: 0px;
  text-transform: uppercase;
  margin-left: 3px;
  margin-right: 3px;
}

body .p-dialog .p-dialog-footer button {
  margin-left: 5px;
  margin-right: 0px;
}
/*body .p-button:hover {
    box-shadow:5px 5px;
  }*/
body .p-inputtext:enabled:focus:not(.ui-state-error) {
  box-shadow: 0 0 0 0.2em var(--action--edit-color);
}
body .ui-inputtext:enabled:focus:not(.ui-state-error) {
  box-shadow: 0 0 0 0.2em var(--action--edit-color);
}

body .p-dropdown .p-dropdown-label {
  padding: 5px !important;
  text-align: left;
}
.p-autocomplete .p-autocomplete-dropdown {
  padding-top: 2px;
  background-color: var(--nav-main-light-background-color);
}

body .p-autocomplete .p-button.p-button-icon-only {
  width: 2.358em;
}

body
  .p-autocomplete-panel
  .p-autocomplete-items
  .p-autocomplete-list-item.ui-state-highlight {
  background-color: var(--nav-main-light-background-color);
}
/*TABLE HEADER TXT*/
.table-header-text {
  font-family: var(--input-font-family);
  font-size: 13px;
  text-transform: uppercase;
  color: var(--nav-main-light-background-color);
  padding-right: 5px !important;
  text-transform: uppercase;
  font-weight: 800 !important;
  font-family: var(--header-font-family) !important;
}

.table-header-text-save-pdf {
  font-family: var(--input-font-family);
  font-weight: bold;
  font-size: 9px;
  text-transform: uppercase;
  color: var(--nav-main-light-background-color);
}

.p-datatable .p-datatable-header {
  background-color: #f4f4f4;
  border: none;
}
.p-datatable .p-datatable-header div span i {
  color: var(--p-text-color);
}

body .gs-table .p-datatable .p-datatable-thead {
  display: table-header-group;
}

body .gs-table .p-datatable .p-datatable-thead > tr {
  display: table-row;
}

body .gs-table .p-datatable .p-datatable-thead > tr > th {
  display: table-cell;
}

body .gs-table .p-datatable .p-datatable-tbody {
  display: table-row-group;
}

body .gs-table .p-datatable .p-datatable-tbody > tr {
  display: table-row;
}

body .gs-table .p-datatable .p-datatable-tbody > tr > td {
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 10px;
  padding-right: 0px;
  border: 0;
  display: table-cell;
}

body .gs-table .p-datatable .p-datatable-tfoot > tr > td {
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 10px;
  padding-right: 0px;
}
body .gs-table .p-datatable .p-datatable-tbody > tr > th {
  padding-top: 3px;
  padding-bottom: 3px;
  border: 0;
}

body .gs-table .p-datatable .p-datatable-tfoot > tr > td {
  padding-top: 3px;
  padding-bottom: 3px;
  border: 0;
  /*color: var(--nav-main-light-background-color);*/
}

body .gs-table .p-datatable .p-datatable-thead > tr > th {
  background-color: var(--content-main-background-color-light);
  color: var(--nav-main-background-color);
  border-bottom: 1px solid var(--content-main-background-topandbottom-border);
  border-top: 1px solid var(--content-main-background-topandbottom-border);
  border-left: 0px;
  border-right: 0px;
  place-content: center;
}
/*table row odd*/
body .gs-table .p-datatable .p-datatable-tbody > tr {
  background-color: var(--content-main-background-color-light);
  border-bottom: 1px solid var(--content-main-background-topandbottom-border);
  border-top: 1px solid var(--content-main-background-topandbottom-border);
}
/*table row even*/
body .gs-table .p-datatable .p-datatable-tbody > tr:nth-child(even) {
  background-color: var(--content-main-background-color-dark);
  border-bottom: 1px solid var(--content-main-background-topandbottom-border);
  border-top: 1px solid var(--content-main-background-topandbottom-border);
}

body .p-datatable .p-sortable-column.p-highlight {
  background-color: var(--nav-main-light-background-color) !important;
  color: var(--text-nav-color) !important;
}

/*table month calendar*/
body .gs-table-month-calendar .p-datatable .p-datatable-thead > tr > th {
  background-color: var(--nav-main-light-background-color);
  color: var(--text-nav-color);
  border: 1px solid var(--content-main-background-topandbottom-border);
  display: table-cell;
  width: 14%;
}
/*table row odd*/
body .gs-table-month-calendar .p-datatable .p-datatable-tbody > tr {
  background-color: var(--content-main-background-color-light);
  border: 1px solid var(--content-main-background-topandbottom-border);
}
/*table row even*/
body
  .gs-table-month-calendar
  .p-datatable
  .p-datatable-tbody
  > tr:nth-child(even) {
  background-color: var(--content-main-background-color-light);
  border: 1px solid var(--content-main-background-topandbottom-border);
}

.gs-float-label {
  top: -0.75rem !important;
  font-size: 13px !important;
  margin-top: -4px !important;
}

body .gs-table-month-calendar .p-datatable .p-datatable-tbody > tr > td {
  vertical-align: baseline;
}

.gs-table-expand {
  color: var(--nav-main-background-color);
  display: flex;
}
.gs-table-expand:hover {
  text-decoration: none;
}

.table-row-text {
  font-family: var(--input-font-family);
  font-weight: normal;
  font-size: 13px;
  /*text-transform: uppercase;*/
}

.table-row-text-save-pdf {
  font-family: var(--input-font-family);
  font-weight: normal;
  font-size: 9px;
  /*text-transform: uppercase;*/
}

.trafficlight-orange {
  background-color: var(--in-progress--orange);
  color: var(--text-nav-color);
  border-radius: 12px;
  height: 25px;
  width: 80px;
  text-align: center;
  line-height: 25px;
  margin-left: auto;
  margin-right: auto;
}

.trafficlight-green {
  background-color: var(--action--edit-color);
  color: var(--text-nav-color);
  border-radius: 12px;
  height: 25px;
  width: 80px;
  text-align: center;
  line-height: 25px;
  margin-left: auto;
  margin-right: auto;
}

.table-control-middle {
  display: block;
  margin: auto;
  /* width: inherit;*/
  max-width: inherit;
}

.table-control-inherit {
  width: inherit;
  max-width: inherit;
}

.table-control-fill {
  width: -webkit-fill-available;
}

.table-control-middle .p-inputswitch {
  display: block;
  margin: auto;
}

.table-control-inherit .p-inputtext {
  width: inherit;
  max-width: inherit;
}
.table-control-inherit .p-inputtext {
  width: inherit;
  max-width: inherit;
}

.table-footer .p-inputtext {
  font-weight: bold !important;
}

.table-control-inherit .p-calendar {
  width: inherit;
  max-width: inherit;
}

.table-control-inherit .p-spinner .p-spinner-input {
  width: 50px;
  padding-right: 0;
}

.table-control-inherit .p-spinner .p-spinner-button {
  width: 20px;
  background-color: var(--nav-main-light-background-color);
}

.table-control-inherit .p-calendar {
  width: inherit;
}

.table-control-inherit .p-inputnumber {
  width: inherit;
}

.gs-control-inherit .p-spinner {
  width: 45%;
}

.gs-control-inherit
  .p-inputnumber-input
  .gs-control-inherit
  .p-spinner
  .p-spinner-input {
  text-align: inherit;
}

.table-control-fill .p-inputtext {
  width: -webkit-fill-available;
}

.table-control-fill .p-calendar {
  width: -webkit-fill-available;
}

.table-control-middle .p-inputtext {
  width: inherit;
  max-width: inherit;
}

.table-control-middle .ui-inputnumber {
  width: inherit;
  max-width: inherit;
}

.table-control-middle .p-calendar {
  width: inherit;
  max-width: inherit;
}

.report-payment-totals {
  background-color: #e9f5ff !important;
  color: var(--master-color) !important;
  font-weight: bold;
}

.report-payment-totals .p-inputtext {
  color: var(--master-color) !important;
  font-weight: bold !important;
}

.ui-tabmenuitem.ui-state-default:enabled:hover {
  background-color: var(--nav-main-light-background-color) !important;
  color: var(--text-nav-color) !important;
}

.table-control.p-button.p-button-secondary:enabled:hover {
  background-color: var(--nav-main-light-background-color) !important;
  color: var(--text-nav-color) !important;
}
.table-control.p-button.p-button-secondary {
  background-color: #eaeaea;
  border: none;
}

.p-button.ui-button-secondary {
  background-color: #eaeaea;
}

body .ui-rating a .ui-rating-icon {
  font-size: 25px;
  color: var(--nav-main-light-background-color);
}
/*MULTISELECT DROPDOWN*/
body .p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
  background-color: var(--nav-main-light-background-color);
}

body .p-multiselect-items-wrapper .p-checkbox .p-checkbox-box.p-highlight {
  background-color: transparent; /*var(--action--edit-color);*/
  border: 2px solid var(--content-main-background-color-light);
  color: var(--content-main-background-color-light);
}

.p-paginator-rpp-options.p-dropdown .p-dropdown-label.p-inputtext {
  height: inherit;
}

/*body .ui-multiselect-items-wrapper .p-checkbox .p-checkbox-box {
  background-color: transparent;
  border: 2px solid var(--text-nav-color);
  color: var(--nav-main-light-background-color);
}*/

/*PAGINATOR*/
body .p-paginator .p-paginator-current {
  float: left;
  /* display: inline-block; */
  min-width: 200px;
  position: absolute;
  left: 5px;
}

body .p-paginator-bottom{
  display: none !important;
}

body .p-paginator {
  text-align: center;
  border: none;
  height: 60px;
  padding-top: 5px;
  margin-bottom: 5px;
}

body .p-paginator .p-paginator-pages .p-paginator-element.p-highlight {
  background-color: var(--nav-main-light-background-color);
}

p-paginator-element .p-datatable .p-paginator-bottom {
  padding-top: 5px;
  padding-bottom: 5px;
}

.p-dropdown .p-dropdown-trigger {
  width: 25px;
}

body .p-paginator .p-dropdown .p-dropdown-trigger {
  background-color: var(--nav-main-light-background-color);
  color: white;
  left: 25px;
}

span.p-checkbox-icon {
  color: #174579 !important;
  font-size: 14px !important;
  font-weight: bold;
}

div.p-checkbox-box {
  background: white !important;
}

.p-radiobutton .p-radiobutton-box.p-highlight {
  border-color: var(--navigation-master-color) !important;
  background-color: var(--navigation-master-color) !important;
}

.p-radiobutton-label {
  margin: 0 0 0 5px;
}

body .p-paginator .p-dropdown .p-dropdown-label {
  /* height: 32px; */
  /*  margin-right: 5px;*/
  width: 53px;
}

.p-datatable-globalfilter-container {
  text-align: left;
}

body .p-datatable .p-datatable-caption {
  border: none;
}
/*SELECT BUTTON*/
.shiftdaysselectbutton .p-selectbutton .p-button.p-highlight {
  background-color: var(--action--edit-color);
}
.shiftdaysselectbutton .p-selectbutton .p-button {
  background-color: var(--nav-main-light-background-color);
  border: 0px;
  color: var(--text-nav-color);
  height: 36px;
}
.shiftdaysselectbutton .p-selectbutton {
  background-color: var(--nav-main-light-background-color);
  display: block;
  padding: 0px;
  height: 36px;
  border-radius: 0px !important;
}

.shiftdaysselectbutton .p-selectbutton .p-button div:first-of-type {
  width: min-content;
  display: flex;
  padding: 0px !important;
}

body .lightborder .p-checkbox .p-checkbox-box.p-highlight {
  background-color: transparent; /*var(--action--edit-color);*/
  border: 2px solid var(--content-main-background-color-light);
  color: var(--content-main-background-color-light);
}
body .lightborder .p-checkbox .p-checkbox-box {
  background-color: transparent;
  border: 2px solid var(--text-nav-color);
  color: var(--nav-main-light-background-color);
  /*      var(--nav-main-light-background-color);*/
}

body .p-checkbox.p-checkbox-checked {
  background-color: var(--navigation-master-color);
}

body .lightborder .p-checkbox .p-checkbox-box .p-checkbox-icon {
  font-size: 17px;
}

body .darkborder .p-checkbox .p-checkbox-box.p-highlight {
  background-color: transparent; /*var(--action--edit-color);*/
  border: 2px solid var(--nav-main-light-background-color);
  color: var(--nav-main-light-background-color);
}

body .darkborder .p-checkbox .p-checkbox-box {
  background-color: transparent;
  border: 2px solid var(--nav-main-light-background-color);
  color: var(--nav-main-light-background-color);
  /*      var(--nav-main-light-background-color);*/
}

body .darkborder .p-checkbox .p-checkbox-box .p-checkbox-icon {
  font-size: 17px;
}

/*INPUTS*/
body .p-dropdown .p-dropdown-trigger {
  background-color: var(--nav-main-light-background-color);
  color: white;
  border-radius: var(--input-border-radius);
}

body .p-inputtext {
  border-radius: var(--input-border-radius) !important;
  border: var(--input-border);
  font-family: var(--input-font-family);
  font-weight: var(--input-font-weight) !important;
  font-size: var(--input-font-size) !important;
  /*text-transform: uppercase;*/
}

body .p-dropdown {
  min-width: 20px;
  width: inherit;
  max-width: inherit;
  border: none;
}

.p-dropdown .p-dropdown-label {
  border: var(--input-border);
}

body .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  background-color: var(--nav-main-light-background-color);
}

body .p-dropdown:not(.ui-state-disabled).ui-state-focus {
  box-shadow: 0 0 0 0.2em var(--action--edit-color);
}
body .ui-corner-all {
  border-radius: var(--input-border-radius) !important;
}
body .p-dropdown-label-container {
  width: inherit;
  max-width: inherit;
  text-align: left;
}
/*DIALOG*/
body .p-dialog .p-dialog-header {
  background-color: var(--nav-main-light-background-color);
  color: white;
  text-transform: uppercase;
  height: 50px;
  border: 0;
  font-family:var(--header-font-family) !important;
}

.gs-dialog_close {
  outline: none;
  margin-left: 5px;
  background-color: transparent !important;
  color: white;
  height: 20px;
  position: absolute;
  right: 5px;
}

.p-inputtext.hover-textbox {
  border: none !important;
  /*padding: 0px !important;*/
  background: transparent !important;
  color: black;
}
.ui-inputtext.hover-textbox {
  border: none !important;
  /*padding: 0px !important;*/
  background: transparent !important;
}

.p-inputtext.hover-textbox:hover {
  /*padding: 0.429em !important;*/
  background-color: white !important;
  color: black;
}
.ui-inputtext.hover-textbox:hover {
  /*padding: 0.429em !important;*/
  background-color: white !important;
}

.p-inputtext.hover-textbox:focus {
  /*padding: 0.429em !important;*/
  background-color: white !important;
  border: inherit !important;
  color: black;
}
.ui-inputtext.hover-textbox:focus {
  /*padding: 0.429em !important;*/
  background-color: white !important;
  border: inherit !important;
}
gs-time .p-inputnumber.p-component.p-inputnumber-buttons-stacked {
  width: 52px;
  height: 34px;
}

gs-time .p-inputnumber-button {
  background-color: var(--navigation-master-color);
}

span.p-inputnumber {
  width: inherit;
}

.currency {
  text-align: right;
}

.currencydisabled {
  text-align: right;
  border: none !important;
  background-color: transparent !important;
  pointer-events: none !important;
}

.table-disabled {
  text-align: right;
  border: none !important;
  background-color: transparent !important;
  pointer-events: none !important;
}

.right .ui-inputnumber-input {
  text-align: right;
}

.rightDisabled .ui-inputnumber-input {
  text-align: right;
  /*  border: none;*/
  background-color: transparent;
  pointer-events: none;
  color: #858585 !important;
}

/*ACCORDION*/

.inherit-max-width {
  max-width: inherit;
}
.p-accordion {
  max-width: inherit;
}

p-accordiontab p-header p-checkbox {
  display: table-row;
}

.p-accordion .p-accordion-header .p-accordion-header-link {
  padding: 0.571em 1em;
  border: 1px solid #c8c8c8;
  color: #333333;
  background-color: #f4f4f4;
  color: #333333;
  font-weight: 700;
  border-radius: 3px;
  transition: background-color 0.2s, box-shadow 0.2s;
  text-decoration: none;
}

.p-accordion
  .p-accordion-header:not(.p-highlight):not(.p-disabled):hover
  .p-accordion-header-link {
  background-color: #cac7c7;
  color: white;
}

.wizard
  .p-accordion
  .p-accordion-header:not(.ui-state-disabled).p-highlight
  a
  .p-accordion-toggle-icon {
  color: var(--nav-main-light-background-color) !important;
}

.wizard
  .p-accordion
  .p-accordion-header:not(.ui-state-disabled).p-highlight
  a
  .p-accordion-toggle-icon:hover {
  color: var(--nav-main-light-background-color);
}

.wizard
  .p-accordion
  .p-accordion-header:not(.ui-state-disabled).p-highlight:hover
  a {
  background-color: var(--content-main-background-color-dark);
  color: black;
  border-left: 4px solid var(--nav-main-light-background-color);
}
.wizard .p-accordion .p-accordion-header:not(.ui-state-disabled).p-highlight a {
  background-color: var(--content-main-background-color-dark);
  border: var(--input-border);
  border-left: 4px solid var(--nav-main-light-background-color);
  color: black;
}

.wizard .p-accordion .p-accordion-header a:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0;
}

.wizard .p-accordion .p-accordion-content {
  background: var(--content-main-background-color-dark);
  border: var(--input-border);
  /* border-left: 4px solid var(--nav-main-light-background-color); */
}

.wizard .p-accordion .p-accordion-header a:focus {
  box-shadow: none;
}

.g5.monthly-overview .p-accordion .p-accordion-header a {
  background-color: var(--nav-main-light-background-color);
  color: var(--text-nav-color);
  height: auto;
  padding: 0;
}

.g5.monthly-overview .p-accordion .p-accordion-tab {
  margin-bottom: 0px;
}

.g5.monthly-overview .p-accordion .p-accordion-header a {
  border: 0px;
}

.g5.monthly-overview
  .p-accordion
  .p-accordion-header
  a
  .p-accordion-toggle-icon {
  float: right;
  margin-top: 20px;
}

.g5.monthly-overview .p-accordion .p-accordion-content {
  border: none;
  padding-left: 20px;
  padding-top: 0px;
  padding-bottom: 5px;
  padding-right: 0px;
}

.g5 .p-accordion .p-accordion-header :hover {
  border: none !important;
}
.g5 .p-accordion .p-accordion-header {
  background-color: var(--text-nav-color-selected);
  color: var(--text-nav-color);
}

.g5 .p-accordion .p-accordion-header a {
  background-color: var(--text-nav-color-selected);
  color: var(--text-nav-color);
  height: 50px;
}

.g5 .p-accordion .p-accordion-header:not(.ui-state-disabled) {
  background-color: var(--text-nav-color-selected) !important;
  color: var(--text-nav-color) !important;
}
.g5
  .p-accordion
  .p-accordion-header:not(.p-highlight):not(.ui-state-disabled):hover
  a {
  background-color: var(--text-nav-color-selected) !important;
  color: var(--text-nav-color) !important;
}
.g5
  .p-accordion
  .p-accordion-header:not(.ui-state-disabled).p-highlight:hover
  a {
  background-color: var(--text-nav-color-selected) !important;
  color: var(--text-nav-color) !important;
  border-left: 4px solid var(--nav-main-light-background-color) !important;
}
.g5 .p-accordion .p-accordion-header:not(.ui-state-disabled).p-highlight a {
  background-color: var(--text-nav-color-selected) !important;
  border: var(--input-border);
  border-left: 4px solid var(--nav-main-light-background-color);
}

.g5.p-accordion
  .p-accordion-header:not(.ui-state-disabled).p-highlight
  a
  .p-accordion-toggle-icon {
  color: var(--text-nav-color);
}

.g5 .p-accordion .p-accordion-header a .p-accordion-toggle-icon {
  color: var(--text-nav-color);
}

.g5 .p-accordion .p-accordion-header a:focus {
  box-shadow: none;
}

.g5-daily-status .p-accordion .day-open .p-accordion-header {
  background-color: var(--calendar-day-open) !important;
  color: var(--text-nav-color);
}

.g5-daily-status .p-accordion .day-open .p-accordion-header a {
  background-color: var(--calendar-day-open) !important;
  color: var(--text-nav-color);
  height: 50px;
}

.g5-daily-status .p-accordion .day-open .p-accordion-header a .header-right {
  position: absolute;
  right: 10px;
}


.g5-daily-status .p-accordion .day-paid .p-accordion-header {
  background-color: var(--calendar-day-paid) !important;
  color: var(--text-nav-color);
}

.g5-daily-status .p-accordion .day-paid .p-accordion-header a {
  background-color: var(--calendar-day-paid) !important;
  color: var(--text-nav-color);
  height: 50px;
}

  .g5-daily-status .p-accordion .day-paid .p-accordion-header a .header-right {
    position: absolute;
    right: 10px;
  }

.g5-daily-status
  .p-accordion
  .day-approved
  .p-accordion-header
  a
  .header-right {
  position: absolute;
  right: 10px;
}

.g5-daily-status
  .p-accordion
  .day-open
  .p-accordion-header
  a
  .p-accordion-toggle-icon {
  color: var(--text-nav-color);
}

.g5-daily-status
.p-accordion
.day-paid
.p-accordion-header
a
.p-accordion-toggle-icon {
  color: var(--text-nav-color);
}

.g5-daily-status
  .p-accordion
  .day-open
  .p-accordion-header:not(.p-highlight):not(.ui-state-disabled):hover
  a {
  background-color: var(--calendar-day-open) !important;
  color: var(--text-nav-color) !important;
}

.g5-daily-status
.p-accordion
.day-paid
.p-accordion-header:not(.p-highlight):not(.ui-state-disabled):hover
a {
  background-color: var(--calendar-day-paid) !important;
  color: var(--text-nav-color) !important;
}

.g5-daily-status
  .p-accordion
  .day-open
  .p-accordion-header:not(.ui-state-disabled).p-highlight:hover
  a {
  background-color: var(--calendar-day-open) !important;
  color: var(--text-nav-color) !important;
  border-left: 4px solid var(--nav-main-light-background-color) !important;
}

.g5-daily-status
.p-accordion
.day-paid
.p-accordion-header:not(.ui-state-disabled).p-highlight:hover
a {
  background-color: var(--calendar-day-paid) !important;
  color: var(--text-nav-color) !important;
  border-left: 4px solid var(--nav-main-light-background-color) !important;
}

.g5-daily-status
  .p-accordion
  .day-open
  .p-accordion-header:not(.ui-state-disabled).p-highlight
  a {
  background-color: var(--calendar-day-open) !important;
  border: var(--input-border);
  border-left: 4px solid var(--nav-main-light-background-color);
}

.g5-daily-status
.p-accordion
.day-paid
.p-accordion-header:not(.ui-state-disabled).p-highlight
a {
  background-color: var(--calendar-day-paid) !important;
  border: var(--input-border);
  border-left: 4px solid var(--nav-main-light-background-color);
}

.g5-daily-status .p-accordion .day-open .p-accordion-header a:focus {
  box-shadow: none;
}


.g5-daily-status .p-accordion .day-paid .p-accordion-header a:focus {
  box-shadow: none;
}

.g5-daily-status .p-accordion .day-approved .p-accordion-header {
  background-color: var(--calendar-day-approved) !important;
  color: var(--text-nav-color);
}

.g5-daily-status .p-accordion .day-approved .p-accordion-header a {
  background-color: var(--calendar-day-approved) !important;
  color: var(--text-nav-color);
}

.g5-daily-status
  .p-accordion
  .day-approved
  .p-accordion-header
  a
  .p-accordion-toggle-icon {
  color: var(--text-nav-color);
}

.g5-daily-status
  .p-accordion
  .day-approved
  .p-accordion-header:not(.p-highlight):not(.ui-state-disabled):hover
  a {
  background-color: var(--calendar-day-approved) !important;
  color: var(--text-nav-color) !important;
}

.g5-daily-status
  .p-accordion
  .day-approved
  .p-accordion-header:not(.ui-state-disabled).p-highlight:hover
  a {
  background-color: var(--calendar-day-approved) !important;
  color: var(--text-nav-color) !important;
  border-left: 4px solid var(--nav-main-light-background-color) !important;
}

.g5-daily-status
  .p-accordion
  .day-approved
  .p-accordion-header:not(.ui-state-disabled).p-highlight
  a {
  background-color: var(--calendar-day-approved) !important;
  border: var(--input-border);
  border-left: 4px solid var(--nav-main-light-background-color);
}

.g5-daily-status .p-accordion .day-approved .p-accordion-header a:focus {
  box-shadow: none;
}

.g5-daily-status .p-accordion .day-no-data .p-accordion-header {
  background-color: var(--calendar-day-no-data) !important;
  color: var(--text-nav-color);
}

.g5-daily-status .p-accordion .day-no-data .p-accordion-header a {
  background-color: var(--calendar-day-no-data) !important;
  color: var(--text-nav-color);
  height: 50px;
}

.g5-daily-status
  .p-accordion
  .day-no-data
  .p-accordion-header
  a
  .p-accordion-toggle-icon {
  color: var(--text-nav-color);
}

.g5-daily-status
  .p-accordion
  .day-no-data
  .p-accordion-header:not(.p-highlight):not(.ui-state-disabled):hover
  a {
  background-color: var(--calendar-day-no-data) !important;
  color: var(--text-nav-color) !important;
}

.g5-daily-status
  .p-accordion
  .day-no-data
  .p-accordion-header:not(.ui-state-disabled).p-highlight:hover
  a {
  background-color: var(--calendar-day-no-data) !important;
  color: var(--text-nav-color) !important;
  border-left: 4px solid var(--nav-main-light-background-color) !important;
}

.g5-daily-status
  .p-accordion
  .day-no-data
  .p-accordion-header:not(.ui-state-disabled).p-highlight
  a {
  background-color: var(--calendar-day-no-data) !important;
  border: var(--input-border);
  border-left: 4px solid var(--nav-main-light-background-color);
}

.g5-daily-status .p-accordion .day-no-data .p-accordion-header a:focus {
  box-shadow: none;
}

/*CALENDAR*/
body .p-calendar.p-calendar-w-btn .p-datepicker-trigger.p-button {
  background-color: var(--nav-main-light-background-color);
}

body .p-calendar.p-calendar-w-btn .p-inputtext {
  width: inherit;
  max-width: inherit;
  height: auto;
}

main-calendar p-calendar .p-datepicker-calendar-container {
  display: inline-block;
}

main-calendar p-calendar.not-range .p-calendar {
  width: 100%;
}

main-calendar .p-datepicker table td {
  padding: 0px;
  border-radius: 0;
  border: 1px solid white;
}

main-calendar .p-disabled {
  opacity: 1 !important;
}

.p-calendar input.p-component {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

span.p-dropdown-label {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

p-table table {
  display: table;
}

p-table table tr {
  display: table-row;
}
p-table table th {
  display: table-caption;
}
p-table table td {
  display: table-cell;
}

div.p-dropdown-trigger {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

main-calendar .p-datepicker table td > span {
  border-radius: 0;
  height: 46px;
  width: 50px;
  line-height: 25px;
  padding: 0px;
  border: none;
}

main-calendar .p-datepicker table td > span .day-no-data {
  text-align: center;
  width: 50px;
}

main-calendar .p-datepicker table td > span .day-open {
  width: 50px;
  text-align: center;
}

main-calendar .p-datepicker table td > span .day-approved {
  width: 50px;
  text-align: center;
}

  main-calendar .p-datepicker table td > span .day-paid {
    width: 50px;
    text-align: center;
  }

main-calendar .p-datepicker table th {
  font-weight: normal;
  text-transform: uppercase;
  padding: 0px;
}
main-calendar .p-datepicker .p-datepicker-header .p-datepicker-title button {
  text-transform: uppercase;
  font-size: 22px;
  line-height: 10px;
  color: #457ed9 !important;
  font-weight: 400 !important;
}
main-calendar .p-datepicker {
  border: none;
}

main-calendar .p-datepicker-calendar {
  margin: 0 !important;
}
main-calendar .p-datepicker-header {
  padding: 0 !important;
}

main-calendar .selected-date-single {
  position: absolute;
  margin-top: 6px;
  margin-right: 3px;
  margin-left: 3px;
  width: 44px;
  height: 33px;
  border-top: 2px solid white;
  border-bottom: 2px solid white;
  border-right: 2px solid white;
  border-left: 2px solid white;
  border-radius: 45px 45px 45px 45px;
}

main-calendar .selected-date-range-last {
  position: absolute;
  margin-top: 6px;
  margin-right: 3px;
  width: 47px;
  height: 33px;
  border-top: 2px solid white;
  border-bottom: 2px solid white;
  border-right: 2px solid white;
  border-radius: 0px 45px 45px 0px;
}

main-calendar .selected-date-range-first {
  position: absolute;
  margin-top: 6px;
  margin-left: 3px;
  width: 47px;
  height: 33px;
  border-top: 2px solid white;
  border-bottom: 2px solid white;
  border-left: 2px solid white;
  border-radius: 45px 0px 0px 45px;
}

main-calendar .selected-date-in-range {
  position: absolute;
  margin-top: 6px;
  width: 50px;
  height: 33px;
  border-top: 2px solid white;
  border-bottom: 2px solid white;
}

/*RADIO BUTTON*/
body .ui-radiobutton .ui-radiobutton-box.p-highlight {
  background-color: var(--nav-main-light-background-color);
  border-color: var(--nav-main-light-background-color);
}
/*RADIO BUTTON SECTION*/
.radio-button-section {
  border: 3px solid lightgrey;
  border-radius: 15px;
}
.radio-button-section .header-title {
  top: -15px;
  position: absolute;
  padding: 5px;
}

.master-font {
  font-family: var(--input-font-family);
}

.ui-inputnumber {
  width: inherit;
  max-width: inherit;
  min-width: 10px !important;
}
/* Customize the label (the container) */
.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  margin-top: 10px;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: lightgray;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: var(--nav-main-light-background-color);
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.container .checkmark:after {
  top: 6px;
  left: 6px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}

.distribution-title {
  color: var(--nav-main-light-background-color);
  margin-left: 10px;
  font-family: var(--input-font-family);
  font-weight: bold;
  text-transform: uppercase;
  border-bottom: 1px solid var(--nav-main-light-background-color);
}

.distribution-exceptions {
  padding-bottom: 10px;
  padding-top: 10px;
  border-bottom: 2px solid var(--nav-main-light-background-color);
  border-top: 2px solid var(--nav-main-light-background-color);
  background-color: white;
}

.no-event {
  pointer-events: none !important;
}

.hover-event {
  pointer-events: all !important;
}

.pointer-events-auto {
  pointer-events: auto;
}

.disabled {
  pointer-events: none;
  /*opacity:0.8;*/
  background-color: lightgray !important;
}

.disabled_opacity-no-color {
  pointer-events: none;
  opacity: 0.6;
}

.disabled-datetime .p-inputtext {
  pointer-events: none;
  opacity: 1.0;
  background-color: lightgray !important;
}

.disabled_opacity {
  pointer-events: none;
  opacity: 0.6;
  background-color: lightgray !important;
}
body .disabled .p-inputtext {
  pointer-events: none;
  /*opacity:0.8;*/
  background-color: lightgray !important;
}
.disabled.p-button {
  opacity: 0.8;
}
/*NEW LOCATION*/
.new-location .p-dialog-content {
  min-width: 1000px;
  overflow-x: hidden;
}

.signup .p-dialog-content {
  min-width: 500px;
  overflow-x: hidden;
}

.dashboard-tab {
  padding: 10px;
  background-color: white;
  color: black;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  border: 1px solid #b0b0b0;
  height: 55px;
  line-height: 33px;
  font-family: var(--header-font-family) !important;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .dashboard-tab {
    font-size: 10px;
  }
}

.dashboard-tab.selected-tab {
  background-color: var(--dashboard-tab-selected) !important;
}

.dashboard-time {
  border-radius: 5px;
  align-self: center;
  padding: 11px;
  background-color: var(--dashboard-tab-selected);
  color: white;
  text-align: center;
  margin-right: 15px;
  margin-left: 15px;
  max-width: 200px;
  font-family: var(--header-font-family) !important;
}
.day-open {
  background-color: #174579 !important;
  color: white !important;
}
.day-approved {
  background-color: var(--text-nav-color-selected); /* #52b93b !important;*/
  color: white !important;
}
.day-open-total {
  background-color: #174579 !important;
  color: white !important;
}
.day-approved-total {
  background-color: var(--text-nav-color-selected) !important;
  color: white !important;
}
.day-no-data {
  background-color: #d60015 !important;
  color: white !important;
}

.day-paid-total {
  background-color: var(--calendar-day-paid) !important;
  color: white !important;
}

.day-paid{
    background-color: #086209 !important;
    color: white !important;
}

.alarm-open {
  color: #174579 !important;
}

.alarm-approved {
  color: var(--text-nav-color-selected) !important; /* #52b93b ;*/
}

.day-legend {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.day-button {
  border: 1px solid;
  padding: 8px;
  margin: 10px;
  cursor: pointer;
}

.day-button-info {
  margin-left: 10px;
  margin-top: -10px;
}

.p-dialog .p-dialog-content {
  overflow-x: hidden;
}

.calendar-mask {
  opacity: 0.3;
}

.selected-day {
  border-radius: 25px;
}

.p-datepicker table td > a:focus {
  box-shadow: none !important;
}

/*FILTER CALCULATOR: https://codepen.io/sosuke/pen/Pjoqqp */
.filter-white {
  filter: invert(0%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%)
    contrast(103%);
}

.filter-gray {
  filter: invert(70%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%)
    contrast(103%);
}

.dashboard-daily-total {
  padding: 10px;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: normal;
  height: 40px !important;
  background-color: white;
  color: black;
  border: 1px solid #b0b0b0;
}

/*POS DETAILS*/
.pos-details .p-dialog-content {
  min-width: 800px;
  max-width: inherit;
  overflow-x: hidden;
}

.p-dialog{
  max-height: 80vh !important;
}

.data-export .p-dialog-content {
  max-width: 90vw;
  min-width: 50vw;;
  max-height: 80vh;
}

.calculated-data-details-wide{
  min-width: 300px;
  max-width: 1500px;
}

.breakdown-details .p-tabview-panels {
  min-height: 250px;
}

.bulk-approve-and-close .p-dialog-content {
  min-width: 900px;
  max-width: 900px;
  overflow-x: hidden;
}

.p-dialog-resizable {
  max-width: inherit;
}

.p-dialog-visible {
  max-width: inherit;
}

.employee-profile-details .p-dialog-content {
  min-width: 950px;
  max-width: 950px;
  min-height: 400px;
  overflow-x: hidden;
}

.transactions-with-end-date .p-dialog-content {
  min-width: 950px;
  min-height: 400px;
  overflow-x: hidden;
}

.new-manager-account .p-dialog-content {
  width: 850px;
  height: 600px;
  overflow-x: hidden;
}

.dialog90 .p-dialog {
  width: 90vw;
}

.dialog100 .p-dialog {
  width: 90%;
}

.dialog35 .p-dialog {
  width: 35vw;
}
.dialog70 .p-dialog {
  width: 70vw;
}
.dialog50 .p-dialog {
  width: 50vw;
}

/*FILE UPLOAD*/

.p-fileupload-buttonbar .p-button {
  background-color: var(--nav-main-light-background-color);
}

body .p-fileupload-row .p-button {
  color: var(--action--delete-color);
  background-color: transparent;
}

/*MENU ITEM*/
body .ui-menu .ui-menuitem-link:hover {
  background-color: var(--nav-main-background-color);
}
body .ui-menuitem-link {
  background-color: var(--nav-main-light-background-color);
}

/*TAB MENUITEM*/

body .ui-tabmenu .ui-tabmenu-nav .ui-tabmenuitem {
  border: none;
}

body .ui-tabmenu .ui-tabmenu-nav .ui-tabmenuitem.p-highlight {
  border: none;
}

body .ui-tabmenu .ui-tabmenu-nav .ui-tabmenuitem.p-highlight .ui-menuitem-link {
  background-color: var(--text-nav-color-selected);
  color: var(--text-nav-color);
}

/* body .ui-tabmenu .ui-tabmenu-nav .ui-tabmenuitem .ui-menuitem-link {
  background-color: var(--nav-main-light-background-color);
} */

body
  .ui-tabmenu
  .ui-tabmenu-nav
  .ui-tabmenuitem.p-highlight
  .ui-menuitem-link
  .ui-menuitem-text {
  color: var(--text-nav-color);
}

body
  .ui-tabmenu
  .ui-tabmenu-nav
  .ui-tabmenuitem
  .ui-menuitem-link
  .ui-menuitem-text {
  color: var(--text-nav-color);
}

body
  .ui-tabmenu
  .ui-tabmenu-nav
  .ui-tabmenuitem:not(.p-highlight):not(.ui-state-disabled):hover
  .ui-menuitem-link
  .ui-menuitem-text {
  color: var(--text-nav-color);
}

p-tabmenu
  .ui-tabmenu
  .ui-tabmenu-nav
  .ui-tabmenuitem:not(.p-highlight):not(.ui-state-disabled)
  .ui-menuitem-link
  .ui-menuitem-text {
  color: var(--text-main-color-selected) !important;
}

p-tabmenu
  .ui-tabmenu
  .ui-tabmenu-nav
  .ui-tabmenuitem:is(.p-highlight):not(.ui-state-disabled)
  .ui-menuitem-link
  .ui-menuitem-text {
  color: var(--text-nav-color) !important;
}

body .ui-tabmenu .ui-tabmenu-nav .ui-tabmenuitem .ui-menuitem-link {
  color: var(--text-main-color-selected);
  background-color: var(--content-main-background-color-dark);
}

body .ui-tabmenu .ui-tabmenu-nav .ui-tabmenuitem.p-highlight .ui-menuitem-link {
  color: var(--text-nav-color) !important;
  background-color: var(--text-main-color-selected) !important;
}

body .ui-menu .ui-menuitem-link:hover .ui-menuitem-text {
  color: var(--text-nav-color);
  font-family: var(--input-font-family);
  font-weight: bold;
  font-size: 13px;
}
body .ui-menu .ui-menuitem-link:hover .ui-menuitem-text {
  color: var(--text-nav-color);
  font-family: var(--input-font-family);
  font-weight: bold;
  font-size: 13px;
  text-transform: uppercase;
}

body .ui-menu .ui-menuitem-link .ui-menuitem-text {
  color: var(--text-nav-color);
  font-family: var(--input-font-family);
  font-weight: bold;
  font-size: 13px;
  text-transform: uppercase;
}

body .ui-menu .ui-menuitem-link:hover .ui-menuitem-icon {
  color: var(--text-nav-color);
}

body .ui-menu .ui-menuitem-link .ui-menuitem-icon {
  color: var(--text-nav-color);
}

.link-text{
  color: var(--master-color);
  cursor: pointer;
  text-decoration: underline;
}

body .ui-menu.ui-menu-dynamic {
  width: auto;
}

.manager-account-details-location .p-listbox {
  width: 100%;
}

.manager-account-details-location .p-inputtext {
  border: none !important;
}

.manager-account-details-location
  .p-listbox
  .p-listbox-list
  .p-listbox-item
  .p-checkbox {
  float: left;
}

body .p-listbox .p-listbox-list .p-listbox-item.p-highlight {
  background-color: var(--nav-main-light-background-color);
}

body
  .p-listbox
  .p-listbox-list
  .p-listbox-item
  .p-checkbox
  .p-checkbox-box.p-highlight {
  border: none;
  background-color: var(--text-nav-color-selected);
}

.month-calendar-day-disabled {
  background-image: url(/../../assets/images/line-bg.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  pointer-events: none;
}

.employee-month-overview-event {
  /* background-color: var(--nav-main-light-background-color); */
  color: var(--text-nav-color);
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
}

.employee-month-overview-event-title {
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
}

.employee-month-overview-event-text {
  font-size: 12px;
  font-weight: normal;
  text-transform: none;
}

.employee-month-overview-event-action {
  height: 30px;
  margin-left: 5px;
  margin-top: 5px;
  margin-right: 0px;
  margin-bottom: 5px;
  font-size: 12px;
  font-weight: normal;
  color: var(--text-nav-color);
  background-color: var(--nav-main-light-background-color);
}

.monthly-overview-details-shift-header {
  padding: 5px;
  margin-bottom: 5px;
  color: var(--text-nav-color);
  background-color: var(--nav-main-light-background-color);
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
}

.monthly-overview-details-shift-details {
  padding: 5px;
  border-bottom: 1px solid var(--nav-main-light-background-color);
}

.monthly-overview-details-shift-details-header {
  background-color: var(--text-nav-color);
  color: var(--nav-main-light-background-color);
  font-size: 14px;
  font-weight: bold;
}

.monthly-overview-details-shift-details-description {
  background-color: var(--text-nav-color);
  color: var(--nav-main-light-background-color);
  font-size: 14px;
}

.monthly-overview-details-shift-details .p-dialog-content {
  min-width: 600px;
  min-height: 250px;
  max-height: 500px;
  overflow-x: hidden;
}

/*TABVIEW*/
body .p-tabview-nav-content .p-tabview-nav li.p-highlight a {
  border: none;
  background-color: var(--text-nav-color-selected) !important;
  color: var(--text-nav-color) !important;
}
body .p-tabview-nav-content .p-tabview-nav li a {
  border: none;
  background-color: var(--nav-main-light-background-color) !important;
  color: var(--text-nav-color) !important;
}

body .p-tabview-nav-content .p-tabview-nav li.p-tabview-selected a {
  color: var(--text-nav-color);
}

body .p-tabview-nav-content .p-tabview-nav li a {
  color: var(--text-nav-color);
}
/*
@media print {

  @page {
    size: landscape;
  }


  img {
    height: 90%;
    margin: 0;
    padding: 0;
  }
}
*/
.time_change_log {
  border: 1px solid white;
  padding: 5px;
  border-radius: 25%;
  color: white;
  font-weight: bold;
  font-size: 11px;
}
.time_change_log.add {
  background-color: #00a44e;
}

.time_change_log.update {
  background-color: #db7c00;
}

.time_change_log.remove {
  background-color: crimson;
}
.time_change_log.rolback {
  background-color: #73d0ec;
}

.time_weekly_per_employee {
  margin-right: 0px;
  margin-left: -10px;
  margin-bottom: -3px;
  border-left: 1px solid var(--nav-main-background-color);
  border-right: 1px solid var(--nav-main-background-color);
  border-bottom: 1px solid var(--nav-main-background-color);
}

.payroll-export-history {
  background-color: transparent !important;
  color: var(--navigation-master-color) !important;
  box-shadow: none !important;
}

.ui-fieldset-legend {
  width: auto;
  padding: 5px !important;
  margin-bottom: -15px;
}

.ui-fieldset {
  border: none !important;
}

.p-fieldset .p-fieldset-legend {
  padding: 0.857rem 1rem;
  border: 0px;
  color: #333333;
  background: white;
  font-weight: 700;
  border-radius: 3px;
  font-family : var(--header-font-family) !important;
}
.p-fieldset {
  border: 0px;
}

.p-fileupload .p-fileupload-buttonbar {
  background: #f4f4f4;
  color: #333333;
  border: 1px solid #eaeaea;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}
.p-fileupload .p-fileupload-content {
  border: 1px solid #eaeaea;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}

.visa-choice_section {
  width: 5px !important;
  height: 40px;
  position: absolute;
  top: 15px;
}
.visa-choice_section.merchant {
  background: blue;
}
.visa-choice_section.customer_bank {
  background: rgb(92, 153, 105);
}
.visa-choice_section.ownership {
  background: rgb(251, 255, 0);
}
.visa-choice_section.additional_info {
  background: rgb(162, 0, 255);
}

.visa-choice_section.ach {
  background: rgb(162, 0, 255);
}
.alarms-diff-jobs-header {
  background: var(--content-main-background-color-dark);
}
.alarms-diff-jobs-header .alarms-diff-jobs-text {
  text-align: -webkit-center;
  text-transform: uppercase;
}
.truncate {
  width: 1000px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

wage-calculation .p-inputwrapper .p-inputnumber .p-inputnumber-input {
  width: 70%;
}

.tabapay-reconciliation-table{
    min-width:auto !important;
}

.tabapay-reconciliation-table th {
  display: table-cell;
  color: var(--nav-main-background-color);
  text-align: center;
  width: 150px;
}

.tabapay-reconciliation-table td {
    text-align:center;
    width:150px;
}

.calendar-from-to .p-datepicker table td {
  padding: 0px;
}

.calendar-from-to .date-from .p-datepicker.p-component {
  min-width: 322px !important;
  box-shadow: none;
  height: 430px;
}
.calendar-from-to .date-from .p-inputtext.p-component {
  width: 0px !important;
  padding: 0px;
  border: none;
  min-width: 0px;
}

.calendar-from-to .date-to .p-datepicker.p-component {
  left: 321px !important;
  min-width: 322px !important;
  box-shadow: none;
  border-left: none;
  height: 430px;
}
.calendar-from-to .date-to .p-datepicker.p-component::before {
  content: "";
  height: 60%;
  width: 1px;
  position: absolute;
  left: 0;
  bottom: 50px;
  background-color: rgb(200, 200, 200);
}
.calendar-from-to-header-close {
  content: "x";
  height: 35px;
  width: 35px;
  position: absolute;
  top: -15px;
  right: -15px;
  text-align: center;
  color: white;
  background: #007bff;
  font-size: 21px;
  border-radius: 50%;
  cursor: pointer;
}
.calendar-from-to .date-to .p-inputtext.p-component {
  width: 0px !important;
  padding: 0px;
  border: none;
  min-width: 0px;
}
.calendar-from-to-header-text {
  font-size: 18px;
  text-align: center;
  color: var(--master-color);
}
.calendar-from-to-header-text .arrow {
  position: absolute;
  left: -10px;
}
.calendar-from-to-footer {
  overflow: hidden;
  position: absolute;
  bottom: 5px;
}
.calendar-from-to-footer p {
  padding: 10px 0px;
}
.calendar-from-to-footer.right {
  right: 2px;
}
.calendar-from-to-footer.left {
  left: 2px;
}
.calendar-from-to-footer span p {
  display: inline-block;
  padding: 0px 10px;
  color: var(--master-color);
  cursor: pointer;
  font-size: 13px;
  margin: 0px;
}
.calendar-from-to-footer span p:hover {
  color: gray;
}
.calendar-range-from-to {
  background-color: #c4d9f8;
  height: 46px;
  line-height: 46px;
  width: 50px;
  text-align: center;
}
.calendar-range-from-to-highlight {
  background-color: var(--master-color) !important;
  height: 46px;
  line-height: 46px;
  width: 50px;
  text-align: center;
  color: white;
}
.calendar-from-to .p-datepicker-calendar tr > th {
  padding: 0px !important;
}

.calendar-from-to .p-datepicker table td.p-datepicker-today > span {
  background-color: white;
}

.calendar-from-to .p-datepicker table td.p-datepicker-today > span.p-highlight {
  background-color: var(--master-color) !important;
}
.calendar-from-to
  .p-datepicker
  table
  td.p-datepicker-today
  > span.p-highlight
  > div {
  background-color: var(--master-color) !important;
}
.calendar-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.calendar-from-to .form-valid-icon.with-icon {
  right: 5px !important;
}

.check-adjustments-selected-employee {
  background-color: #52b93b4d !important;
}

.dialog90scroll .p-dialog {
  width: 90%;
  overflow-x: scroll;
}
  .dialog90scroll .p-dialog .p-dialog-content {
    overflow-x: scroll;
  }

  /*---------------------------------------------------------------------------*/
  tr p-table .table-header-text{
      font-size:11px;
      padding-top:0px;
      padding-bottom:0px;
  }

  tr p-table .pi{
      font-size:11px;
  }

tr p-table .p-datatable .p-datatable-thead > tr > th {
  padding-top: 0px;
  padding-bottom: 0px;
}

tr p-table .p-datatable .p-datatable-header{
    padding: 0px;
}

tr p-table .table-row-text{
    font-size:12px;
}

tr p-table .p-inputtext {
  font-size: 12px !important;
}

.check-adj-dirty-item {
  border-left: 3px solid var(--nav-main-light-background-color);
}

.component-groups-table .p-paginator {
    height:100px;
}

.component-groups-table .p-paginator-current {
    width:100% !important;
    display:inline-block !important;
    bottom:50px;
}

.company-group-details fieldset{
    margin-bottom:15px;
}


.company-group-details legend {
  font-size:15px;
  text-align:left;
}

.selected-table-row {
 font-weight:900;
}

/*------------------HEADERS FONT CHANGE---------------------------------------------------------*/
.p-accordion-header{
    font-family: var(--header-font-family) !important;
}

.p-overlaypanel {
  margin-top:0px !important;
}

.checkbox-lined label{
    margin-bottom:0px;
}

.marginLeft30{
    margin-left:30px;
}

.background-green {
  background-color: var(--action--edit-color);
}


.blue-accordion .p-accordion .p-accordion-header :hover {
  border: none !important;
}

.blue-accordion .p-accordion .p-accordion-header {
  background-color: var(--nav-main-light-background-color);
  color: var(--text-nav-color);
}

  .blue-accordion .p-accordion .p-accordion-header a {
    background-color: var(--nav-main-light-background-color);
    color: var(--text-nav-color);
    height: 40px;
  }

  .blue-accordion .p-accordion .p-accordion-header:not(.ui-state-disabled) {
    background-color: var(--nav-main-light-background-color) !important;
    color: var(--text-nav-color) !important;
  }

  .blue-accordion
  .p-accordion
  .p-accordion-header:not(.p-highlight):not(.ui-state-disabled):hover
  a {
    background-color: var(--nav-main-light-background-color) !important;
    color: var(--text-nav-color) !important;
  }

  .blue-accordion
  .p-accordion
  .p-accordion-header:not(.ui-state-disabled).p-highlight:hover
  a {
    background-color: var(--nav-main-light-background-color) !important;
    color: var(--text-nav-color) !important;
  }

  .blue-accordion .p-accordion .p-accordion-header:not(.ui-state-disabled).p-highlight a {
    background-color: var(--nav-main-light-background-color) !important;
  }

    .blue-accordion .p-accordion
    .p-accordion-header:not(.ui-state-disabled).p-highlight
    a
    .p-accordion-toggle-icon {
      color: var(--text-nav-color);
    }

  .blue-accordion .p-accordion .p-accordion-header a .p-accordion-toggle-icon {
    color: var(--text-nav-color);
  }

  .blue-accordion .p-accordion .p-accordion-header a:focus {
    box-shadow: none;
  }

  .no-left-margin {
      margin-left:0px !important;
  }

.blue-button {
  background-color: var(--nav-main-light-background-color) !important;
}

.blue-button:hover{
    background-color: var(--action--edit-color) !important;
}

  .blue-button:disabled:hover {
    background-color: var(--nav-main-light-background-color) !important;
  }
  .p-toast-bottom-left.toast-max{
    left: 250px !important;
  }

  .p-toast-top-center{
    width: 100% !important;
    top: 0px !important;
  }

  .p-toast-message{
    margin-bottom:5px !important;
  }


.inputswitch-label{
  text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.inputswitch-right{
  padding-left: 10px;
}
.p-picklist .p-picklist-header{
  background-color: var(--nav-main-light-background-color);
  color: white;
}
.p-picklist-target-controls{
  display: none !important;
}
.p-picklist-source-controls{
  display: none !important;
}
.p-disabled{
  opacity: 1;

}
.video-container{
  position: relative;
  width: 100vw;
  height: 100vh;
  z-index: 99999;
  /* top: 50%; */
  padding-top: 50px;
  background: #000000b5;
}
.video-frame{
  position: absolute;
  top: 50px;
  left: 50%;
  width: 90%;
  height: 90%;
  transform: translate(-50%, 0);
}
.video-palyer{
  position: relative;
}
.video-close{
  position: absolute;
  top: 30px;
  right: 30px;
  width: 30px;
  height: 30px;
  z-index: 20;
  border-radius: 50%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: white;
  font-size: 22px;
}

/* .video-icon::after {
  content: url(../../../assets/icons/VIDEO_ICON.png);
  display: inline-block;
  width: 20px;
  height: 20px;
  cursor: pointer;
} */

.video-icon{
  cursor: pointer;
  margin-left: 5px;
}
.video-icon-small{
  width: 15px;
  height: 15px;
}
.video-icon-normal{
  width: 20px;
  height: 20px;
}
.video-icon-absolute{
  position: absolute;
  right: -5px;
}
.video-icon-absolute-top{
 top:-10px;
}
.video-icon-absolute-bottom{
  bottom: -10px;
  right: -15px;
}

.video-button{
  position: absolute;
  right: -5px;
  top: -25px;
}
.video-button-container{
  display: inline-block;
}
.d-inline-flex{
  display: inline-flex;
}
.video-title{
  word-break: break-all;
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.iframe-video-title{
  font-family: 'Montserrat';
  font-size: 25px;
  font-weight: 600;
  padding-right: 10px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.new-tab-video{
  background: white !important;
  color: black !important;
  border: 1px solid black !important;
  width: 262px;
}
.video-selected{
  font-weight: 600;
  color: var(--nav-main-background-color);
}
.justifiy-space-between{
  justify-content: space-between;
}
.justify-center{
  justify-content:center;
}
.p-overlaypanel .p-overlaypanel-close{
  z-index: 1;
}
.self-center{
  align-self: center;
}
.video-switch{
  zoom: 80%;
  display: flex;
  align-self: center;
  padding-right: 3px;
}
.p-datatable-wrapper{
  width: 100% !important;
}
.w-background{
  background: white !important;
}

.p-inputnumber-buttons-stacked .p-inputnumber-button-group{

  height: 33px;
}
.p-inputnumber-buttons-stacked .p-button.p-inputnumber-button-up{
  background-color: var(--nav-main-background-color);
  width: 20px;
}
.p-inputnumber-buttons-stacked .p-button.p-inputnumber-button-down{
  background-color: var(--nav-main-background-color);
  width: 20px;
}

.chat-menu-link {
  width: 100%;
  font-size: 14px;
  cursor: pointer;
}
  .chat-menu-link:hover {
    color: black;
  }

.chat-menu-link-icon {
  padding-top: 7px;
  padding-right: 10px;
  padding-left: 10px;
  font-weight: bold;
  font-size: 1rem;
}

.chat-gpt-prompt input {
  border: none !important;
  outline: none !important;
  user-select:none !important;
}
  .chat-gpt-prompt input:focus {
    border: none !important;
    outline: none !important;
    user-select: none !important;
  }

.chat-spiner-container {
  width: 350px;
  height: 350px;
  margin: 0;
  padding: 0;
  background: transparent;
  position: fixed;
  z-index: 15000;
}

.empl-profile-branch-initiate_btn{
    background-color: transparent !important;
    border: 1px solid orange !important;
    color:orange !important;
    border-radius:13px;
    width:100px !important;

}
  .empl-profile-branch-initiate_btn:hover {
    background-color: transparent !important;
    border: 1px solid green !important;
    color: green !important;
  }

.empl-profile-branch-update_btn {
  background-color: transparent !important;
  border: none !important;
  color: #333333 !important;
}

  .empl-profile-branch-update_btn .p-button-label{
      color: #333333 !important;
      pointer-events:none;
      text-transform:none !important;

  }
  .empl-profile-branch-update_btn .p-button-icon {
      color: black !important;
      cursor:pointer;
  }

  .empl-profile-branch-update_btn:hover {
    background-color: transparent !important;
    border: none !important;
    font-weight: bolder;
    color: black !important;
  }


.chat-gpt-prompt-input::-webkit-input-placeholder { /* WebKit, Blink, Edge */
  color: #828282;
}

.chat-gpt-prompt-input:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
  color: #828282;
  opacity: 1;
}

.chat-gpt-prompt-input::-moz-placeholder { /* Mozilla Firefox 19+ */
  color: #828282;
  opacity: 1;
}

.chat-gpt-prompt-input:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #828282;
}

.chat-gpt-box {
  font-family: 'Open Sans';
  position: fixed;
  bottom: 80px;
  right: 30px;
  width: 450px;
  height: 600px;
  border-radius: 20px;
  background-color: white;
  box-shadow: 0px 0px 18px -9px rgba(66, 68, 90, 1);
  z-index: 5000;
}

.p-highlight{
  .gs-float-label{
    color: white;
  }
}

.remove-action{
  display: flex;
  justify-content: end;
  .remove-connector{
    cursor: pointer;
    width: 30px;
    padding: 3px;
    text-align: center;
    i{
      color: var(--calendar-day-no-data);
    }
  }
  .remove-connector:hover{
    background-color: var(--s-border-color);
    border-radius: 50%;
  }
}

.background-color-erorr {
    background-color: red !important;
    color:white !important;
}

    .background-color-erorr:hover {
        background-color: #174579 !important;
    }
.row-expanded{
  border: 2px solid var( --main-color) !important;
  border-top: none !important;
}
.row-expanded-parent{
  border: 2px solid var( --main-color) !important;
  border-bottom: none !important;
  background-color: #1745792b !important;
}
.toolbar-text{
  display: flex;
    align-self: center;
    padding: 0 5px;
}
.toolbar-separator{
  border-right: 1.5px solid grey;
  height: 30px;
  align-self: center;
}
.height-max{
  height: 100%;
}
.toolbar-icon{
  font-size: 25px;
}
.align-center{
  align-items: center;
}

.p-toast-message-text{
  width: 95px !important;
}
.p-toast-detail{
  white-space: pre-line !important;
}
.text_link{
  text-align: justify;
  cursor: pointer;
  color: blue;
}
.text_link:hover{
  text-decoration: underline;
}
.gs-modal {
  text-align: left;
  width: fit-content;
}

.gs-modal-button {
  background-color: transparent !important;
  color: #174579;
  font-size: xx-small;
}
.gs-modal-button:hover {
  color: #174579 !important;
}

.long-label {
  text-wrap: nowrap !important;
}

.label-remove-opacity span {
  opacity: 1 !important;
}

.label-is-virtual {
  font-size: 13px;
  margin-left: 9px;
  margin-right: 100px;
}

.gs-confirmationService-reject {
  background: gray !important
}

.service-message-container {
  color: red;
  position: absolute;
  left: 30%;
  padding-top: 5px;
}

.service-message {
  padding-left: 8px;
  text-overflow: ellipsis;
  width: 550px;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  float: left;
  cursor: pointer;
  font-size: 16px;
  text-align: center;
}
